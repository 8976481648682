// src/components/Malaka/ClientsViewer.js

import React, { useState, useEffect, useMemo } from 'react';
import '../../styles/Malaka/ClientsViewer.css';
import { FaEdit, FaSearch, FaPrint } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import jsPDF from 'jspdf';

// Componente para cada tarjeta de cliente (ClientCard)
const ClientCard = ({ client, navigate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Evita que el clic en "Editar" cierre la tarjeta
    const encodedPgcCode = encodeURIComponent(client.pgcCode);
    const targetRoute = `/malaka/client/edit/${encodedPgcCode}`;
    navigate(targetRoute);
  };

  return (
    <div
      className={`client-card ${isOpen ? 'open' : ''}`}
      onClick={toggleCard}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') toggleCard();
      }}
      aria-expanded={isOpen}
      aria-label={`Cliente ${client.name}`}
    >
      <div className="client-header">
        <div>
          <h3>Nombre: {client.name}</h3>
          <p>Código PGC: {client.pgcCode}</p>
          <p>Razón Social: {client.companyName}</p>
        </div>
        <button
          className="edit-button"
          onClick={handleEdit}
          aria-label={`Editar Cliente ${client.name}`}
        >
          <FaEdit /> Editar
        </button>
      </div>
      {isOpen && (
        <div className="client-details">
          <p>
            <strong>Tipo de Cliente:</strong> {client.clientType}
          </p>
          <p>
            <strong>Rango de visitas:</strong> {client.visitRange}
          </p>
          <p>
            <strong>Dirección:</strong> {client.address}
          </p>
          <p>
            <strong>NIF:</strong> {client.nif}
          </p>
          <p>
            <strong>Teléfono:</strong> {client.telephoneNumber}
          </p>
          <p>
            <strong>Email:</strong> {client.email}
          </p>
          <p>
            <strong>Código Postal:</strong> {client.postalCode}
          </p>
          <p>
            <strong>Localidad:</strong> {client.city}
          </p>
          <p>
            <strong>Provincia:</strong> {client.province}
          </p>
          <p>
            <strong>Notas:</strong> {client.notes}
          </p>
        </div>
      )}
    </div>
  );
};

function ClientsViewer() {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortKey, setSortKey] = useState('pgcCode');
  const [sortOrder, setSortOrder] = useState('asc');

  // Estado para manejar la visibilidad del modal de impresión
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [printOption, setPrintOption] = useState('all'); // 'all', 'city', 'province'
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedVisitRange, setSelectedVisitRange] = useState('');

  // Fetch clients from the server
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axiosInstance.get('/clients'); // Ruta de tu API
        setClients(response.data);
      } catch (error) {
        console.error('Error al obtener clientes:', error);
      }
    };
    fetchClients();
  }, []);

  // Obtener lista única de provincias
  const uniqueProvinces = useMemo(() => {
    const provinces = clients.map((client) => client.province).filter(Boolean);
    return Array.from(new Set(provinces)).sort();
  }, [clients]);

  // Obtener lista única de ciudades
  const uniqueCities = useMemo(() => {
    const cities = clients.map((client) => client.city).filter(Boolean);
    return Array.from(new Set(cities)).sort();
  }, [clients]);

  // Obtener lista única de rangos de visitas
  const uniqueVisitRanges = useMemo(() => {
    const visitRanges = clients.map((client) => client.visitRange).filter(Boolean);
    return Array.from(new Set(visitRanges)).sort();
  }, [clients]);

  // Handle change in search filter
  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  // Handle change in sorting
  const handleSortChange = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  // Filter and sort clients
  const filteredAndSortedClients = useMemo(() => {
    const filtered = clients.filter((client) => {
      const lowerCaseFilter = filter.toLowerCase();
      return (
        String(client.pgcCode).toLowerCase().includes(lowerCaseFilter) ||
        client.name?.toLowerCase().includes(lowerCaseFilter) ||
        client.companyName?.toLowerCase().includes(lowerCaseFilter)
      );
    });

    const sorted = filtered.sort((a, b) => {
      let valueA = a[sortKey];
      let valueB = b[sortKey];

      if (sortKey === 'apertureDate' || sortKey === 'closureDate') {
        valueA = new Date(a[sortKey]);
        valueB = new Date(b[sortKey]);
      }

      if (typeof valueA === 'string') valueA = valueA.toLowerCase();
      if (typeof valueB === 'string') valueB = valueB.toLowerCase();

      if (sortOrder === 'asc') {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
      }
    });

    return sorted;
  }, [clients, filter, sortKey, sortOrder]);

  // Función para abrir el modal de impresión
  const openPrintModal = () => {
    setIsPrintModalOpen(true);
  };

  // Función para cerrar el modal de impresión
  const closePrintModal = () => {
    setIsPrintModalOpen(false);
    // Resetear opciones seleccionadas
    setPrintOption('all');
    setSelectedCity('');
    setSelectedProvince('');
  };

  // Función para manejar la impresión de etiquetas
  const handlePrintLabels = async () => {
    try {
      // Validaciones según la opción seleccionada
      if (printOption === 'city' && selectedCity.trim() === '') {
        alert('Por favor, selecciona una ciudad.');
        return;
      }
      if (printOption === 'province' && selectedProvince.trim() === '') {
        alert('Por favor, selecciona una provincia.');
        return;
      }
      if (printOption === 'visitRange' && selectedVisitRange.trim() === '') {
        alert('Por favor, selecciona un rango de visitas.');
        return;
      }

      // Obtener todas las direcciones
      const response = await axiosInstance.get('/clients/address');
      let addresses = response.data;

      // Filtrar según la opción seleccionada
      if (printOption === 'city' && selectedCity) {
        addresses = addresses.filter(
          (addr) => addr.city.toLowerCase() === selectedCity.toLowerCase()
        );
      } else if (printOption === 'province' && selectedProvince) {
        addresses = addresses.filter(
          (addr) => addr.province.toLowerCase() === selectedProvince.toLowerCase()
        );
      } else if (printOption === 'visitRange' && selectedVisitRange) {
        addresses = addresses.filter(
          (addr) => addr.visitRange.toLowerCase() === selectedVisitRange.toLowerCase()
        );
      }

      if (addresses.length === 0) {
        alert('No se encontraron direcciones para la opción seleccionada.');
        return;
      }

      // Crear un nuevo documento PDF con tamaño A4
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'cm',
        format: 'a4',
      });

      const pageWidth = 21; // cm
      const pageHeight = 29.7; // cm

      const labelWidth = 8; // cm
      const labelHeight = 3.7; // cm

      const columns = 2;
      const rows = 8;
      const labelsPerPage = columns * rows;

      const marginX = (pageWidth - (columns * labelWidth)) / (columns + 1) + 0.2; // Espacio lateral
      const marginY = (pageHeight - (rows * labelHeight)) / (rows + 1) + 0.15; // Espacio superior e inferior

      const gapX = marginX; // Espacio entre columnas
      const gapY = marginY; // Espacio entre filas

      addresses.forEach((addr, index) => {
        const labelIndex = index % labelsPerPage;

        if (index !== 0 && labelIndex === 0) {
          doc.addPage();
        }

        const col = labelIndex % columns;
        const row = Math.floor(labelIndex / columns);

        const x = marginX + col * (labelWidth + gapX);
        const y = marginY + row * (labelHeight + gapY);

        // Opcional: Dibujar un rectángulo para visualizar las etiquetas (útil durante el desarrollo)
        // doc.setDrawColor(0);
        // doc.rect(x, y, labelWidth, labelHeight);

        // Primera fila: ID y Nombre
        doc.setFontSize(9);
        doc.setFont('helvetica', 'bold');
        const firstLine = `${addr.id} - ${addr.name}`;
        const firstLineText = doc.splitTextToSize(firstLine, labelWidth - 0.4); // 0.2 cm de margen
        doc.text(firstLineText, x + 0.2, y + 0.8);

        // Segunda fila: Dirección
        doc.setFontSize(9);
        doc.setFont('helvetica', 'normal');
        const addressLine = `${addr.address || 'N/A'}`;
        const addressText = doc.splitTextToSize(addressLine, labelWidth - 0.4);
        doc.text(addressText, x + 0.2, y + 1.4);

        // Tercera fila: CP, Ciudad, Provincia
        const thirdLine = `${addr.postalCode || 'N/A'}, ${addr.city || 'N/A'}, ${addr.province || 'N/A'}`;
        const thirdLineText = doc.splitTextToSize(thirdLine, labelWidth - 0.4);
        doc.text(thirdLineText, x + 0.2, y + 2.0);
      });

      // Abrir el PDF en una nueva ventana
      doc.output('dataurlnewwindow');
      // Alternativamente, para descargar automáticamente:
      // doc.save('etiquetas.pdf');

      // Cerrar el modal después de generar el PDF
      closePrintModal();
    } catch (error) {
      console.error('Error al generar etiquetas:', error);
      alert('Ocurrió un error al generar las etiquetas.');
    }
  };

  return (
    <div className="clients-viewer">
      <h2>Clientes</h2>

      {/* Barra de Búsqueda */}
      <div className="search-bar">
        <FaSearch className="search-icon" aria-hidden="true" />
        <input
          type="text"
          placeholder="Buscar por Código PGC, Nombre o Razón Social"
          value={filter}
          onChange={handleFilterChange}
          className="search-input"
          aria-label="Buscar clientes"
        />
      </div>

      {/* Controles de Ordenamiento */}
      <div className="sort-controls">
        <button
          onClick={() => handleSortChange('pgcCode')}
          className="sort-button"
          aria-label={`Ordenar por Código PGC ${sortKey === 'pgcCode'
              ? sortOrder === 'asc'
                ? 'ascendente'
                : 'descendente'
              : 'ascendente'
            }`}
        >
          Ordenar por Código PGC {sortKey === 'pgcCode' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
        <button
          onClick={() => handleSortChange('name')}
          className="sort-button"
          aria-label={`Ordenar por Nombre ${sortKey === 'name'
              ? sortOrder === 'asc'
                ? 'ascendente'
                : 'descendente'
              : 'ascendente'
            }`}
        >
          Ordenar por Nombre {sortKey === 'name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
        <button
          onClick={() => handleSortChange('companyName')}
          className="sort-button"
          aria-label={`Ordenar por Razón Social ${sortKey === 'companyName'
              ? sortOrder === 'asc'
                ? 'ascendente'
                : 'descendente'
              : 'ascendente'
            }`}
        >
          Ordenar por Razón Social{' '}
          {sortKey === 'companyName' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
        </button>
      </div>

      {/* Contenedor de Botones */}
      <div className="create-button-container">
        <button
          className="create-button"
          onClick={() => navigate('/malaka/new-client')}
          aria-label="Crear Nuevo Cliente"
        >
          Crear Nuevo Cliente
        </button>
        <button
          className="print-button"
          onClick={openPrintModal}
          aria-label="Imprimir Etiquetas"
        >
          <FaPrint /> Imprimir Etiquetas
        </button>
      </div>

      {/* Lista de Clientes */}
      {filteredAndSortedClients.length === 0 ? (
        <p className="no-clients">No hay clientes que coincidan con la búsqueda.</p>
      ) : (
        <div className="clients-list">
          {filteredAndSortedClients.map((client) => {
            return <ClientCard key={client.pgcCode} client={client} navigate={navigate} />;
          })}
        </div>
      )}

      {/* Modal de Impresión de Etiquetas */}
      {isPrintModalOpen && (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="print-modal-title">
          <div className="modal-content">
            <h3 id="print-modal-title">Imprimir Etiquetas</h3>
            <div className="modal-options">
              <label>
                <input
                  type="radio"
                  name="printOption"
                  value="all"
                  checked={printOption === 'all'}
                  onChange={() => setPrintOption('all')}
                />
                Todas las etiquetas
              </label>
              <label>
                <input
                  type="radio"
                  name="printOption"
                  value="city"
                  checked={printOption === 'city'}
                  onChange={() => setPrintOption('city')}
                />
                Por Localidad
              </label>
              {printOption === 'city' && (
                <select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  className="modal-select"
                  aria-label="Seleccionar localidad"
                >
                  <option value="">-- Seleccione una localidad --</option>
                  {uniqueCities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              )}
              <label>
                <input
                  type="radio"
                  name="printOption"
                  value="province"
                  checked={printOption === 'province'}
                  onChange={() => setPrintOption('province')}
                />
                Por Provincia
              </label>
              {printOption === 'province' && (
                <select
                  value={selectedProvince}
                  onChange={(e) => setSelectedProvince(e.target.value)}
                  className="modal-select"
                  aria-label="Seleccionar provincia"
                >
                  <option value="">-- Seleccione una provincia --</option>
                  {uniqueProvinces.map((province) => (
                    <option key={province} value={province}>
                      {province}
                    </option>
                  ))}
                </select>
              )}
              <label>
                <input
                  type="radio"
                  name="printOption"
                  value="visitRange"
                  checked={printOption === 'visitRange'}
                  onChange={() => setPrintOption('visitRange')}
                />
                Por Rango de visitas
              </label>
              {printOption === 'visitRange' && (
                <select
                  value={selectedVisitRange}
                  onChange={(e) => setSelectedVisitRange(e.target.value)}
                  className="modal-select"
                  aria-label="Seleccionar rango de visitas"
                >
                  <option value="">-- Seleccione un rango de visitas --</option>
                  {uniqueVisitRanges.map((range) => (
                    <option key={range} value={range}>
                      {range}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="modal-actions">
              <button onClick={handlePrintLabels} className="modal-print-button">
                Generar PDF
              </button>
              <button onClick={closePrintModal} className="modal-close-button">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientsViewer;