import axios from 'axios';

// Crear una instancia de Axios con la URL base del backend
const axiosInstance = axios.create({
  baseURL: 'http://194.164.164.26/v1', // Asegúrate de que esta URL es correcta
  headers: {
    'Content-Type': 'application/json',
  },
});

// Añadir el interceptor de solicitud antes de exportar la instancia
axiosInstance.interceptors.request.use(
  (config) => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      const user = JSON.parse(userStr);
      if (user.accessToken && user.tokenType) {
        config.headers.Authorization = `${user.tokenType} ${user.accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;