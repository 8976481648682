// src/components/Malaka/ClientForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import styles from '../../styles/Malaka/ClientForm.module.css';
import { BlobProvider } from '@react-pdf/renderer';
import ClientePDF from './ClientPDF';

function ClientForm() {
    const { pgcCode } = useParams();
    const navigate = useNavigate();
    const isEditMode = Boolean(pgcCode);

    const [formData, setFormData] = useState({
        id: '',
        pgcCode: '',
        name: '',
        companyName: '',
        address: '',
        nif: '',
        telephoneNumber: '',
        email: '',
        postalCode: '',
        city: '',
        province: '',
        notes: '',
        clientType: '',
        visitRange: '',
        zone: '',
        president: '',
        presidentPhone: '',
        excursionManager: '',
        excursionManagerPhone: '',
        income: '',
        totalCosts: '',
        benefit: '',
    });

    const [expedients, setExpedients] = useState([]);
    const [activeTab, setActiveTab] = useState('Cliente');
    const [isLoading, setIsLoading] = useState(isEditMode);
    const [error, setError] = useState(null);
    const [expandedExpedients, setExpandedExpedients] = useState({});

    useEffect(() => {
        if (isEditMode) {
            const fetchClient = async () => {
                try {
                    const response = await axiosInstance.get('/client/byCode', {
                        params: { pgcCode },
                    });
                    if (response.status === 200 && response.data) {
                        setFormData(response.data);
                        setExpedients(response.data.expedientDTOS || []);
                    } else {
                        setError('No se pudo obtener los datos del cliente.');
                    }
                } catch (err) {
                    console.error('Error al obtener el cliente:', err);
                    setError('Ocurrió un error al obtener los datos del cliente.');
                } finally {
                    setIsLoading(false);
                }
            };
            fetchClient();
        } else {
            const fetchLastClientPgcCode = async () => {
                try {
                    const response = await axiosInstance.get('/last-expedient-number');
                    if (response.status === 200) {
                        const lastPgcCode = response.data.clientPgcCode;
                        const lastClientId = response.data.clientId;
                        setFormData((prevData) => ({
                            ...prevData,
                            pgcCode: lastPgcCode + 1,
                            id: lastClientId + 1,
                        }));
                    }
                } catch (error) {
                    console.error('Error al obtener el último número de expediente:', error);
                    alert('Error al obtener el último número de expediente.');
                }
            };
            fetchLastClientPgcCode();
        }
    }, [isEditMode, pgcCode]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.pgcCode || !formData.name || !formData.companyName) {
            alert('Por favor, completa los campos requeridos (Código PGC, Nombre, Razón Social).');
            return;
        }

        try {
            if (isEditMode) {
                const response = await axiosInstance.put('/client', formData);
                if (response.status === 200) {
                    alert('¡Cliente actualizado exitosamente!');
                    navigate('/malaka/clients');
                } else {
                    alert(`Error al actualizar el cliente: ${response.status} ${response.statusText}`);
                }
            } else {
                const response = await axiosInstance.post('/client', formData);
                if (response.status === 200) {
                    alert('¡Cliente creado exitosamente!');
                    navigate('/malaka/clients');
                } else {
                    alert(`Error al crear el cliente: ${response.status} ${response.statusText}`);
                }
            }
        } catch (err) {
            console.error('Error al enviar el formulario:', err);
            if (err.response) {
                alert(`Error: ${err.response.data.message || err.response.statusText}`);
            } else if (err.request) {
                alert('No se recibió respuesta del servidor.');
            } else {
                alert('Ocurrió un error al configurar la petición.');
            }
        }
    };

    const handleCancel = () => {
        navigate('/malaka/clients');
    };

    const handleViewExpedient = (expedientNumber) => {
        navigate(`/malaka/expedients/edit/${expedientNumber}`);
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Cliente':
                return (
                    <>
                        <h2 className={styles.clientSectionTitle}>Datos del Cliente</h2>

                        {/* 
                            Contenedor en 2 columnas (si el espacio lo permite) 
                            para los distintos fieldsets 
                        */}
                        <div className={styles.columnContainer}>

                            {/* Columna 1 */}
                            <div className={styles.column}>
                                <fieldset className={styles.formSection}>
                                    <legend>Identificación</legend>
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="id">Número*</label>
                                            <input
                                                type="text"
                                                id="id"
                                                name="id"
                                                value={formData.id}
                                                onChange={handleChange}
                                                required
                                                disabled={isEditMode}
                                            />
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className={styles.formSection}>
                                    <legend>Datos fiscales</legend>
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="pgcCode">Código PGC*</label>
                                            <input
                                                type="text"
                                                id="pgcCode"
                                                name="pgcCode"
                                                value={formData.pgcCode}
                                                onChange={handleChange}
                                                required
                                                disabled={isEditMode}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="name">Nombre*</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="companyName">Razón Social*</label>
                                            <input
                                                type="text"
                                                id="companyName"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="nif">NIF</label>
                                            <input
                                                type="text"
                                                id="nif"
                                                name="nif"
                                                value={formData.nif}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="telephoneNumber">Teléfono</label>
                                            <input
                                                type="text"
                                                id="telephoneNumber"
                                                name="telephoneNumber"
                                                value={formData.telephoneNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className={styles.formSection}>
                                    <legend>Tipo y Visitas</legend>
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="clientType">Tipo de Cliente*</label>
                                            <select
                                                id="clientType"
                                                name="clientType"
                                                value={formData.clientType}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Seleccione un tipo de cliente</option>
                                                <option value="Hogares">Hogares</option>
                                                <option value="Asociaciones">Asociaciones</option>
                                                <option value="Clientes">Clientes</option>
                                                <option value="Colaboradores">Colaboradores</option>
                                                <option value="Empresas">Empresas</option>
                                                <option value="Peñas">Peñas</option>
                                                <option value="Colegios">Colegios</option>
                                                <option value="Ayuntamientos">Ayuntamientos</option>
                                                <option value="Iglesias">Iglesias</option>
                                            </select>
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="visitRange">Rango de Visitas</label>
                                            <select
                                                id="visitRange"
                                                name="visitRange"
                                                value={formData.visitRange}
                                                onChange={handleChange}
                                            >
                                                <option value="">Seleccione un rango</option>
                                                <option value="1 - Mensual">1 - Mensual</option>
                                                <option value="2 - Dos meses">2 - Dos meses</option>
                                                <option value="3 - Cuatro meses">3 - Cuatro meses</option>
                                            </select>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            {/* Columna 2 */}
                            <div className={styles.column}>
                                <fieldset className={`${styles.formSection} ${styles.contactSection}`}>
                                    <legend>Dirección</legend>
                                    <div className={styles.formRow}>
                                    <div className={styles.formGroup}>
                                            <label htmlFor="address">Dirección</label>
                                            <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>
                                    <div className={styles.formGroup}>
                                            <label htmlFor="zone">Zona</label>
                                            <input
                                                type="text"
                                                id="zone"
                                                name="zone"
                                                value={formData.zone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="postalCode">Código Postal</label>
                                            <input
                                                type="text"
                                                id="postalCode"
                                                name="postalCode"
                                                value={formData.postalCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="city">Localidad</label>
                                            <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="province">Provincia</label>
                                            <input
                                                type="text"
                                                id="province"
                                                name="province"
                                                value={formData.province}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className={`${styles.formSection} ${styles.contactSection}`}>
                                    <legend>Contacto</legend>
                                    {/* Primera fila: Presidente y su Teléfono */}
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="president">Presidente</label>
                                            <input
                                                type="text"
                                                id="president"
                                                name="president"
                                                value={formData.president}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="presidentPhone">Teléfono</label>
                                            <input
                                                type="text"
                                                id="presidentPhone"
                                                name="presidentPhone"
                                                value={formData.presidentPhone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {/* Segunda fila: Gerente de Excursiones y su Teléfono */}
                                    <div className={styles.formRow}>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="excursionManager">Gerente de Excursiones</label>
                                            <input
                                                type="text"
                                                id="excursionManager"
                                                name="excursionManager"
                                                value={formData.excursionManager}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="excursionManagerPhone">Teléfono</label>
                                            <input
                                                type="text"
                                                id="excursionManagerPhone"
                                                name="excursionManagerPhone"
                                                value={formData.excursionManagerPhone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className={styles.formSectionNotas}>
                                    <legend>Notas</legend>
                                    <div className={styles.formRow}>
                                        <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                                            <label htmlFor="notes">Notas</label>
                                            <textarea
                                                id="notes"
                                                name="notes"
                                                value={formData.notes}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div >
                        </div >
                    </>
                );
            case 'Expedientes':
                return (
                    <div className={styles.card}>
                        <h2>Expedientes</h2>
                        {expedients.length === 0 ? (
                            <p>No hay expedientes agregados.</p>
                        ) : (
                            expedients.map((expedient) => {
                                const isExpanded = expandedExpedients[expedient.id];
                                const toggleExpedient = () => {
                                    setExpandedExpedients((prevState) => ({
                                        ...prevState,
                                        [expedient.id]: !prevState[expedient.id],
                                    }));
                                };

                                return (
                                    <div key={expedient.id} className={styles.expedientCard}>
                                        <div className={styles.expedientHeader} onClick={toggleExpedient}>
                                            <h3>
                                                Expediente #{expedient.expedientNumber} - {expedient.groupName}
                                            </h3>
                                            <span className={styles.expandIcon}>{isExpanded ? '-' : '+'}</span>
                                        </div>
                                        {isExpanded && (
                                            <div className={styles.expedientContent}>
                                                <div className={styles.expedientFormRow}>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Nombre del Grupo</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.groupName || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Fecha de Entrada</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.entryDate || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Fecha de Salida</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.departureDate || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Días</label>
                                                        <input
                                                            type="number"
                                                            value={expedient.days || 0}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.expedientFormRow}>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Vendedor</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.salesman || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Destino</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.destination || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Fecha de Apertura</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.apertureDate || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className={styles.expedientFormGroup}>
                                                        <label>Fecha de Cierre</label>
                                                        <input
                                                            type="text"
                                                            value={expedient.closureDate || '-'}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.buttonGroupRight}>
                                                    <button
                                                        className={styles.viewExpedientButton}
                                                        onClick={() => handleViewExpedient(expedient.expedientNumber)}
                                                    >
                                                        Ver expediente completo
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        )}
                    </div>
                );

            case 'Caja':
                return (
                    <div className={styles.card}>
                        <h2>Caja (por implementar)</h2>
                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label>Ingresos</label>
                                <input type="number" value={formData.income || '0'} readOnly />
                            </div>
                            <div className={styles.formGroup}>
                                <label>Costos Totales</label>
                                <input type="number" value={formData.totalCosts || '0'} readOnly />
                            </div>
                            <div className={styles.formGroup}>
                                <label>Beneficio</label>
                                <input type="number" value={formData.benefit || '0'} readOnly />
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    if (isLoading) {
        return (
            <div className={styles.clientForm}>
                <p>Cargando...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.clientForm}>
                <p className={styles.error}>{error}</p>
            </div>
        );
    }

    return (
        <div className={styles.clientForm}>
            <h1>{isEditMode ? 'Editar Cliente' : 'Crear Nuevo Cliente'}</h1>
            <div className={styles.tabNavigation}>
                <button
                    className={`${styles.tabButton} ${activeTab === 'Cliente' ? styles.active : ''}`}
                    onClick={() => setActiveTab('Cliente')}
                    aria-label="Pestaña Cliente"
                >
                    Cliente
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === 'Expedientes' ? styles.active : ''}`}
                    onClick={() => setActiveTab('Expedientes')}
                    aria-label="Pestaña Expedientes"
                >
                    Expedientes
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === 'Caja' ? styles.active : ''}`}
                    onClick={() => setActiveTab('Caja')}
                    aria-label="Pestaña Caja"
                >
                    Caja
                </button>
            </div>

            <form onSubmit={handleSubmit}>
                {renderTabContent()}
                {activeTab === 'Cliente' && (
                    <div className={styles.buttonGroup}>
                        <button
                            type="submit"
                            className={styles.submitButton}
                            aria-label={isEditMode ? 'Guardar Cambios' : 'Crear Cliente'}
                        >
                            {isEditMode ? 'Guardar Cambios' : 'Crear Cliente'}
                        </button>
                        <button
                            type="button"
                            className={styles.cancelButton}
                            onClick={handleCancel}
                            aria-label="Cancelar"
                        >
                            Cancelar
                        </button>

                        {/* Botón para ver el PDF en otra pestaña */}
                        <BlobProvider document={<ClientePDF formData={formData} />}>
                            {({ url, loading }) =>
                                loading ? (
                                    'Generando PDF...'
                                ) : (
                                    <button
                                        type="button"
                                        className={styles.downloadButton}
                                        onClick={() => window.open(url, '_blank')}
                                    >
                                        Ver PDF
                                    </button>
                                )
                            }
                        </BlobProvider>
                    </div>
                )}
            </form>
        </div>
    );
}

export default ClientForm;
