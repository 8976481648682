import axios from 'axios';

const API_URL = 'http://194.164.164.26/v1/auth/login';

const authService = {
    login: async (username, password) => {
        try {
            const response = await axios.post(API_URL, {
                username,
                password
            });

            if (response.data.accessToken && response.data.tokenType) {
                const user = {
                    accessToken: response.data.accessToken,
                    tokenType: response.data.tokenType,
                    username: username // Puedes añadir más información del usuario si está disponible
                };
                localStorage.setItem('user', JSON.stringify(user));
                // Configurar el token en los encabezados de Axios para futuras solicitudes
                axios.defaults.headers.common['Authorization'] = `${user.tokenType} ${user.accessToken}`;
            }

            return response.data;
        } catch (error) {
            // Manejo de errores
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Usuario o contraseña incorrectos');
            }
        }
    },

    logout: () => {
        localStorage.removeItem('user');
        delete axios.defaults.headers.common['Authorization'];
    },

    getCurrentUser: () => {
        const userStr = localStorage.getItem('user');
        if (userStr) return JSON.parse(userStr);
        return null;
    }
};

export default authService;