// src/components/Malaka/ClientPDF.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    fontSize: 9,
    fontFamily: 'Helvetica',
    color: '#2C3E50',
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
    padding: 30
  },
  contentContainer: {
    width: '90%',
  },
  headerContainer: {
    marginBottom: 20,
    borderBottom: '1 solid #2C3E50',
    paddingBottom: 5,
  },
  /*Primer texto arriba del todo*/ 
  headerTitle: { 
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#2C3E50',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left', 
    marginBottom: -10,
  },
  column: {
    flex: 1,
    marginRight: 10
  },
  lastColumnInRow: {
    marginRight: 0 // Para el último elemento de la fila, no margen derecho
  },
  sectionTitleContainer: {
    marginBottom: 8,
    borderBottom: '1 solid #bdc3c7',
    paddingBottom: 4,
  },
  //Texto encima de cada tarjeta
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#2C3E50',
  },
  fieldGroup: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 10,
    marginBottom: 10,
    border: '1 solid #e0e0e0',
  },
  field: {
    marginBottom: 6,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  label: {
    fontWeight: 'bold',
    color: '#2C3E50',
    marginRight: 5, // Espacio entre etiqueta y valor
    fontSize: 9,
    textAlign: 'left',
    flexShrink: 0, // Evita que la etiqueta se reduzca
  },
  value: {
    color: '#2C3E50',
    flex: 1,
    fontSize: 9,
    textAlign: 'left',
  },
  notesContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: 10,
    border: '1 solid #e0e0e0',
  }
});

const SectionCard = ({ title, fields }) => (
  <View style={{ marginBottom: 10 }}>
    <View style={styles.sectionTitleContainer}>
      <Text style={styles.sectionTitle}>{title}</Text>
    </View>
    <View style={styles.fieldGroup}>
      {fields.map((field, index) => (
        <View style={styles.field} key={index}>
          <Text style={styles.label}>{field.label}</Text>
          <Text style={styles.value}>{field.value}</Text>
        </View>
      ))}
    </View>
  </View>
);

const ClientePDF = ({ formData }) => {
  const clientName = formData.name ? ` - ${formData.name}` : '';

  const infoGeneralFields = [
    { label: 'Código PGC:', value: formData.pgcCode },
    { label: 'Número:', value: formData.id },
    { label: 'Tipo de Cliente:', value: formData.clientType },
    { label: 'Rango de Visitas:', value: formData.visitRange || '-' },
  ];

  const datosContactoFields = [
    { label: 'Nombre:', value: formData.name },
    { label: 'Razón Social:', value: formData.companyName },
    { label: 'NIF:', value: formData.nif || '-' },
    { label: 'Teléfono:', value: formData.telephoneNumber || '-' },
    { label: 'Email:', value: formData.email || '-' },
  ];

  const direccionFields = [
    { label: 'Dirección:', value: formData.address || '-' },
    { label: 'C.P.:', value: formData.postalCode || '-' },
    { label: 'Localidad:', value: formData.city || '-' },
    { label: 'Provincia:', value: formData.province || '-' },
    { label: 'Zona:', value: formData.zone || '-' },
  ];

  const otrosDatosFields = [
    { label: 'Presidente:', value: formData.president || '-' },
    { label: 'Gerente de Excursiones:', value: formData.excursionManager || '-' }
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentContainer}>
          {/* Encabezado */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerTitle}>{clientName}</Text>
          </View>

          {/* Fila 1: Información General + Datos de Contacto */}
          <View style={styles.row}>
            <View style={[styles.column]}>
              <SectionCard title="Información General" fields={infoGeneralFields} />
            </View>
            <View style={[styles.column, styles.lastColumnInRow]}>
              <SectionCard title="Datos de Contacto" fields={datosContactoFields} />
            </View>
          </View>

          {/* Fila 2: Dirección + Otros Datos */}
          <View style={styles.row}>
            <View style={[styles.column]}>
              <SectionCard title="Dirección" fields={direccionFields} />
            </View>
            <View style={[styles.column, styles.lastColumnInRow]}>
              <SectionCard title="Otros Datos" fields={otrosDatosFields} />
            </View>
          </View>

          {/* Fila 3: Notas */}
          <View style={{ marginBottom: 10 }}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>Notas</Text>
            </View>
            <View style={styles.notesContainer}>
              <Text>{formData.notes || 'Sin notas adicionales.'}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ClientePDF;