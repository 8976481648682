export const FORM_LABELS = {
  caseNumber: 'Expediente',
  date: 'Fecha',
  apertureDate: 'Fecha de apertura',
  closureDate: 'Fecha de cierre',
  groupName: 'Nombre del grupo',
  city: 'Localidad',
  province: 'Provincia',
  destination: 'Destino',
  salesman: 'Vendedor',
  suppliers: 'Proveedores',
  supplierDate: 'Fecha', // Cambiado a 'Fecha'
  entryDate: 'Fecha de entrada',
  entryRegimen: 'Régimen de entrada',
  exitDate: 'Fecha de salida',
  exitRegimen: 'Régimen de salida',
  totalRegimen: 'Régimen total',
  releaseDate: 'Fecha Release',
  singleRoomCost: 'Coste Habitación Individual',
  doubleRoomCost: 'Coste Habitación Doble',
  costPerPerson: 'Coste por persona',
  cost: 'Coste',
  priceAdjust: 'Ajuste de precio',
  pax: 'PAX (personas)',
  paxFree: 'Pax.Free',
  bonusPerPax: 'Bono/pax',
  days: 'Días',
  availableRooms: 'Habitaciones disponibles',
  maxPeople: 'Cupo',
  guide: 'Guía',
  notes: 'Notas',
  sales: 'Ventas',
  costs: 'Costes',
  profit: 'Beneficio'
};

export const SEARCH_TYPES = {
  CLIENT_NAME: 'clientName',
  CLIENT_PGC: 'clientPGC',
  EXPEDIENT: 'expedient',
};