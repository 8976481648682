import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../../styles/Malaka/TravelForm.module.css'
import { FORM_LABELS } from '../../utils/constants';
import axiosInstance from '../../services/axiosConfig';
import { FaSearch } from 'react-icons/fa'; // Importar el icono de búsqueda
import Modal from 'react-modal'; // Importar react-modal
import debounce from 'lodash.debounce'; // Importar debounce para optimizar la búsqueda
import RoomingTab from './RoomingTab';
import SummaryTab from './SummaryTab';
import CajaTab from './CajaTab'; // Ajusta la ruta según la ubicación de CajaTab.js
import { SEARCH_TYPES } from '../../utils/constants';
import ExpedientTab from './ExpedientTab'; // Importar el nuevo ExpedientTab

// Configurar el root de la aplicación para react-modal
Modal.setAppElement('#root');


/* ------------------------- Componentes Secundarios ------------------------- */

// Componente SupplierFields
const SupplierFields = ({
  supplier,
  index,
  handleSupplierChange,
  handleSupplierTypeChange,
  removeSupplier,
}) => {
  const SUPPLIER_TYPE_LABELS = {
    Hotel: 'Hotel',
    Restaurant: 'Restaurante',
    Transport: 'Transportista',
    Insurance: 'Seguro',
    Monuments: 'Monumentos',
    Guide: 'Guía',
    Others: 'Otros',
  };

  const renderFieldsByType = () => {
    switch (supplier.type) {
      case 'Hotel':
        return (
          <>
            <div className={`${styles['supplierFormRow']} ${styles['leftAlign']}`}>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`entryDate-${index}`}>{FORM_LABELS.entryDate}</label>
                <input
                  id={`entryDate-${index}`}
                  type="date"
                  name="entryDate"
                  value={supplier.entryDate || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`entryRegimen-${index}`}>{FORM_LABELS.entryRegimen}</label>
                <input
                  id={`entryRegimen-${index}`}
                  type="text"
                  name="entryRegimen"
                  value={supplier.entryRegimen || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`exitDate-${index}`}>{FORM_LABELS.exitDate}</label>
                <input
                  id={`exitDate-${index}`}
                  type="date"
                  name="exitDate"
                  value={supplier.exitDate || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`exitRegimen-${index}`}>{FORM_LABELS.exitRegimen}</label>
                <input
                  id={`exitRegimen-${index}`}
                  type="text"
                  name="exitRegimen"
                  value={supplier.exitRegimen || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`totalRegimen-${index}`}>{FORM_LABELS.totalRegimen}</label>
                <input
                  id={`totalRegimen-${index}`}
                  type="text"
                  name="totalRegimen"
                  value={supplier.totalRegimen || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
            </div>

            <div className={`${styles['supplierFormRow']} ${styles['leftAlign']}`}>
              <div className={`${styles['supplierFormGroup']} ${styles['smallField']}`}>
                <label htmlFor={`releaseDate-${index}`}>{FORM_LABELS.releaseDate}</label>
                <input
                  id={`releaseDate-${index}`}
                  type="date"
                  name="releaseDate"
                  value={supplier.releaseDate || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
            </div>

            <div className={`${styles['supplierFormRow']} ${styles['leftAlign']}`}>

            <div className={`${styles['supplierFormGroup']} ${styles['smallField']}`}>
                <label htmlFor={`costPerPerson-${index}`}>{FORM_LABELS.costPerPerson}</label>
                <input
                  id={`costPerPerson-${index}`}
                  type="number"
                  name="costPerPerson"
                  value={supplier.costPerPerson || 0.0}
                  onChange={(e) => handleSupplierChange(index, e)}
                  step="0.01"
                />
              </div>
              <div className={`${styles['supplierFormGroup']} ${styles['smallField']}`}>
                <label htmlFor={`priceAdjust-${index}`}>{FORM_LABELS.priceAdjust}</label>
                <input
                  id={`priceAdjust-${index}`}
                  type="number"
                  name="priceAdjust"
                  value={supplier.priceAdjust || 0.0}
                  onChange={(e) => handleSupplierChange(index, e)}
                  step="0.01"
                />
              </div>
            </div>
          </>
        );
      case 'Restaurant':
        return (
          <>
            <div className={`${styles['supplierFormRow']} ${styles['leftAlign']}`}>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`date-${index}`}>{FORM_LABELS.supplierDate}</label>
                <input
                  id={`date-${index}`}
                  type="date"
                  name="date"
                  value={supplier.date || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`costPerPerson-${index}`}>{FORM_LABELS.costPerPerson}</label>
                <input
                  id={`costPerPerson-${index}`}
                  type="number"
                  name="costPerPerson"
                  value={supplier.costPerPerson || 0.0}
                  onChange={(e) => handleSupplierChange(index, e)}
                  step="0.01"
                />
              </div>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`priceAdjust-${index}`}>{FORM_LABELS.priceAdjust}</label>
                <input
                  id={`priceAdjust-${index}`}
                  type="number"
                  name="priceAdjust"
                  value={supplier.priceAdjust || 0.0}
                  onChange={(e) => handleSupplierChange(index, e)}
                  step="0.01"
                />
              </div>
            </div>
          </>
        );
      case 'Transport':
      case 'Insurance':
      case 'Monuments':
      case 'Guide':
      case 'Others':
        return (
          <>
            <div className={`${styles['supplierFormRow']} ${styles['leftAlign']}`}>
              <div className={styles.supplierFormGroup}>
                <label htmlFor={`date-${index}`}>{FORM_LABELS.supplierDate}</label>
                <input
                  id={`date-${index}`}
                  type="date"
                  name="date"
                  value={supplier.date || ''}
                  onChange={(e) => handleSupplierChange(index, e)}
                />
              </div>
              <div className={`${styles['supplierFormGroup']} ${styles['smallField']}`}>
                <label htmlFor={`cost-${index}`}>{FORM_LABELS.cost}</label>
                <input
                  id={`cost-${index}`}
                  type="number"
                  name="cost"
                  value={supplier.cost || 0.0}
                  onChange={(e) => handleSupplierChange(index, e)}
                  step="0.01"
                />
              </div>
              {/*<div className={styles.supplierFormGroup}>
                <label htmlFor={`priceAdjust-${index}`}>{FORM_LABELS.priceAdjust}</label>
                <input
                  id={`priceAdjust-${index}`}
                  type="number"
                  name="priceAdjust"
                  value={supplier.priceAdjust || 0.0}
                  onChange={(e) => handleSupplierChange(index, e)}
                  step="0.01"
                />
              </div>*/}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.supplierCard}>
      <div className={styles.supplierHeader}>
        <h3>Proveedor {index + 1}: {supplier.name}</h3>
        <button
          type="button"
          className={styles.cancelButton}
          onClick={() => removeSupplier(index)}
        >
          Eliminar
        </button>
      </div>
      <div className={styles.supplierFormRow}>
        <div className={styles.supplierFormGroup}>
          <label htmlFor={`supplier-type-${index}`}>Tipo de Proveedor</label>
          <input
            id={`supplier-type-${index}`}
            name="type"
            value={SUPPLIER_TYPE_LABELS[supplier.type] || ''}
            onChange={(e) => handleSupplierTypeChange(index, e.target.value)}
            readOnly
          >
          </input>
        </div>
        <div className={styles.supplierFormGroup}>
          <label htmlFor={`supplier-name-${index}`}>Nombre</label>
          <input
            id={`supplier-name-${index}`}
            type="text"
            name="name"
            value={supplier.name || ''}
            onChange={(e) => handleSupplierChange(index, e)}
            readOnly
          />
        </div>
      </div>

      {renderFieldsByType()}

      <div className={styles.supplierFormRow}>
        <div className={styles.supplierFormGroupFullWidth}>
          <label htmlFor={`notes-${index}`}>{FORM_LABELS.notes}</label>
          <textarea
            id={`notes-${index}`}
            name="notes"
            value={supplier.notes || ''}
            onChange={(e) => handleSupplierChange(index, e)}
          />
        </div>
      </div>
    </div>
  );
};

// Componente AdditionalInfo
const AdditionalInfo = ({ formData, handleInputChange }) => {
  return (
    <div className={styles.card}>
      <h2>Información Adicional</h2>
      <div className={`${styles.formRow} ${styles.leftAlign}`}>
        <div className={styles.formGroup}>
          <label htmlFor="pax">{FORM_LABELS.pax}</label>
          <input
            id="pax"
            type="number"
            name="pax"
            value={formData.pax}
            onChange={handleInputChange}
            step="1"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paxFree">{FORM_LABELS.paxFree}</label>
          <input
            id="paxFree"
            type="number"
            name="paxFree"
            value={formData.paxFree}
            onChange={handleInputChange}
            step="1"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="bonusPerPax">{FORM_LABELS.bonusPerPax}</label>
          <input
            id="bonusPerPax"
            type="number"
            name="bonusPerPax"
            value={formData.bonusPerPax}
            onChange={handleInputChange}
            step="0.01"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="maxPeople">{FORM_LABELS.maxPeople}</label>
          <input
            id="maxPeople"
            type="number"
            name="maxPeople"
            value={formData.maxPeople}
            onChange={handleInputChange}
            step="1"
          />
        </div>
      </div>

      <div className={styles.formRow}>
        <div className={`${styles.formGroup} ${styles.fullWidth}`}>
          <label htmlFor="notes">{FORM_LABELS.notes}</label>
          <textarea
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

/* ------------------------- Componente Principal ------------------------- */

// Estado inicial del formulario
const initialFormData = {
  id: '',
  expedientNumber: '',
  apertureDate: '',
  closureDate: '',
  entryDate: '',
  departureDate: '',
  groupName: '',
  salesman: '',
  destination: '',
  situation: 'neutral', // Añadido: Valor por defecto
  expedientType: 'homogeneousGroup', // Añadido: Valor por defecto
  pax: 0,
  paxFree: 0,
  bonusPerPax: 0.0,
  maxPeople: 0,
  days: 0,
  guide: '',
  notes: '',
  pvp: 0.0,
  income: 0.0,
  totalCosts: 0.0,
  benefit: 0.0,
  client: {
    id: null,
    pgcCode: '',
    clientType: '',
    visitRange: '',
    name: '',
    companyName: '',
    address: '',
    nif: '',
    telephoneNumber: '',
    email: '',
    postalCode: '',
    city: '',
    province: '',
    notes: '',
  },
  suppliers: [],
  charges: [],
  payments: [],
  rooms: [],
  moneyCollected: 0.0,
  moneyPending: 0.0,
};

const FloatingWindow = ({
  position,
  handleMouseDown,
  income,
  totalCosts,
  benefit,
  totalCollected,
  totalPendingToCollect,
  totalPaidToSuppliers,
  totalPendingToPay,
}) => {
  return (
    <div
      className={styles.floatingWindow}
      style={{ top: position.y, left: position.x }}
      onMouseDown={handleMouseDown}
    >
      <h3>Resumen Financiero</h3>
      <p className={styles.income}>
        <strong>Ingresos:</strong> {income.toFixed(2)} €
      </p>
      <p className={styles.costs}>
        <strong>Costes:</strong> {totalCosts.toFixed(2)} €
      </p>
      <p className={styles.benefit}>
        <strong>Beneficio Actual:</strong> {benefit.toFixed(2)} €
      </p>
      <hr />
      <p className={styles.collected}>
        <strong>Total Cobrado:</strong> {totalCollected.toFixed(2)} €
      </p>
      <p className={styles.pendingCollect}>
        <strong>Pendiente por Cobrar:</strong> {totalPendingToCollect.toFixed(2)} €
      </p>
      <p className={styles.paidToSuppliers}>
        <strong>Pagado a Proveedores:</strong> {totalPaidToSuppliers.toFixed(2)} €
      </p>
      <p className={styles.pendingPay}>
        <strong>Pendiente por Pagar:</strong> {totalPendingToPay.toFixed(2)} €
      </p>
    </div>
  );
};

function TravelForm() {
  const { expedientNumber } = useParams();
  const navigate = useNavigate();

  // Estado para la lista de proveedores
  const [suppliersList, setSuppliersList] = useState([]);

  // Estado para la ventana modal de búsqueda de proveedores
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);

  // Estado para la búsqueda de proveedores en directo
  const [supplierSearchQuery, setSupplierSearchQuery] = useState('');
  const [supplierSearchResults, setSupplierSearchResults] = useState([]);


  const [formData, setFormData] = useState(initialFormData);
  const [activeTab, setActiveTab] = useState('Expedient'); // Estado para la pestaña activa

  // Estado para la ventana flotante movible
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 20, y: 80 });
  const dragOffset = useRef({ x: 0, y: 0 });

  // Estado para la ventana modal de búsqueda
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchType, setSearchType] = useState(''); // 'clientName', 'clientPGC' o 'expedient'
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Estados para la búsqueda de nombres de clientes
  const [clientNames, setClientNames] = useState([]); // Todos los nombres de clientes (array de strings)
  const [filteredClientNames, setFilteredClientNames] = useState([]);

  // Formatear la fecha del formato "DD/MM/YYYY" al formato "YYYY-MM-DD" para el input
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  // Formatear la fecha del formato "YYYY-MM-DD" al formato "DD/MM/YYYY" para el backend
  const formatDateForBackend = (date) => {
    if (!date) return null;
    const [year, month, day] = date.split('-');
    return `${day}/${month.padStart(2, '0')}/${year}`;
  };

  // Manejo de cambios en la búsqueda en tiempo real para CLIENT_NAME
  const handleSearchInputChange = useMemo(
    () =>
      debounce((query) => {
        setSearchQuery(query);
        if (searchType === SEARCH_TYPES.CLIENT_NAME) {
          if (query.trim() === '') {
            setFilteredClientNames([]);
          } else {
            const filtered = clientNames.filter((clientName) =>
              clientName.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredClientNames(filtered);
          }
        }
      }, 20),
    [clientNames, searchType]
  );

  // Función debounced para manejar cambios en el campo de búsqueda de proveedores
  const handleSupplierSearchInputChange = useMemo(
    () =>
      debounce((query) => {
        if (!query.trim()) {
          setSupplierSearchResults([]);
          return;
        }

        const lowerQuery = query.toLowerCase();
        const filteredSuppliers = suppliersList.filter(
          (supplier) =>
            supplier.name.toLowerCase().includes(lowerQuery) ||
            supplier.pgcCode.toString().includes(lowerQuery)
        );

        setSupplierSearchResults(filteredSuppliers);
      }, 20),
    [suppliersList]
  );

  useEffect(() => {
    return () => {
      handleSearchInputChange.cancel();
      handleSupplierSearchInputChange.cancel();
    };
  }, [handleSearchInputChange, handleSupplierSearchInputChange]);

  // Fetch de proveedores cuando la pestaña de Servicios está activa
  useEffect(() => {
    if (
      (activeTab === 'Services' || activeTab === 'CashRegister') &&
      suppliersList.length === 0
    ) {
      const fetchSuppliers = async () => {
        try {
          const response = await axiosInstance.get('/suppliers');
          if (response.status === 200) {
            setSuppliersList(response.data);
          }
        } catch (error) {
          console.error('Error al obtener los proveedores:', error);
          alert('Ocurrió un error al obtener la lista de proveedores.');
        }
      };
      fetchSuppliers();
    }
  }, [activeTab, suppliersList.length]);

  // Cargar los datos del expediente si estamos en modo edición
  useEffect(() => {
    if (expedientNumber) {
      const fetchExpedient = async () => {
        try {
          const response = await axiosInstance.get(
            `/expedient?expedientNumber=${expedientNumber}`
          );
          if (response.status === 200) {
            const data = response.data;

            // Inicializar formData con los datos del expediente
            const updatedFormData = {
              id: data.id || '',
              expedientNumber: data.expedientNumber || '',
              apertureDate: data.apertureDate ? formatDateForInput(data.apertureDate) : '',
              closureDate: data.closureDate ? formatDateForInput(data.closureDate) : '',
              entryDate: data.entryDate ? formatDateForInput(data.entryDate) : '',
              departureDate: data.departureDate ? formatDateForInput(data.departureDate) : '',
              situation: data.situation || '',
              expedientType: data.expedientType || 'homogeneousGroup',
              groupName: data.groupName || 'neutral',
              salesman: data.salesman || '',
              destination: data.destination || '',
              pax: parseFloat(data.pax) || 0,
              paxFree: parseFloat(data.paxFree) || 0,
              bonusPerPax: parseFloat(data.bonusPerPax) || 0.0,
              maxPeople: parseFloat(data.maxPeople) || 0,
              days: parseFloat(data.days) || 0,
              guide: data.guide || '',
              notes: data.notes || '',
              pvp: parseFloat(data.pvp) || 0.0,
              income: parseFloat(data.income) || 0.0,
              totalCosts: parseFloat(data.totalCosts) || 0.0,
              benefit: parseFloat(data.benefit) || 0.0,
              client: {
                id: null,
                pgcCode: '',
                clientType: '',
                visitRange: '',
                name: '',
                companyName: '',
                address: '',
                nif: '',
                telephoneNumber: '',
                email: '',
                postalCode: '',
                city: '',
                province: '',
                notes: '',
              },
              rooms: data.rooms.map((room) => ({
                roomType: room.roomType,
                passengers: room.passengers.map((passenger) => ({
                  fullName: passenger.fullName,
                })),
              })) || [],
              suppliers: data.suppliers.map((supplier) => ({
                ...supplier,
                id: supplier.id || null, // ID de la relación ExpedientSupplier
                supplierId: supplier.supplierId || null, // ID del proveedor en la tabla de proveedores
                priceAdjust: parseFloat(supplier.priceAdjust) || 0.0,
                costPerPerson: parseFloat(supplier.costPerPerson) || 0.0,
                cost: parseFloat(supplier.cost) || 0.0,
                date: supplier.date ? formatDateForInput(supplier.date) : '',
                entryDate: supplier.entryDate ? formatDateForInput(supplier.entryDate) : '',
                exitDate: supplier.exitDate ? formatDateForInput(supplier.exitDate) : '',
                releaseDate: supplier.releaseDate ? formatDateForInput(supplier.releaseDate) : '',
              })) || [],
              charges: data.charges.map((charge) => ({
                amount: parseFloat(charge.amount) || 0.0,
                date: charge.date ? formatDateForInput(charge.date) : '',
                method: charge.method || '',
                description: charge.description || '',
              })) || [],
              payments: data.payments.map((payment) => ({
                amount: parseFloat(payment.amount) || 0.0,
                date: payment.date ? formatDateForInput(payment.date) : '',
                method: payment.method || '',
                description: payment.description || '',
                supplierId: payment.supplierId || null,
                supplierName: payment.supplierName || '',
              })) || [],
              moneyCollected: parseFloat(data.moneyCollected) || 0.0,
              moneyPending: parseFloat(data.moneyPending) || 0.0,
              passengers: data.passengers || { singleRooms: [], doubleRooms: [], tripleRooms: [] },
            };

            // Verificar si existe 'client' en los datos
            if (data.client) {
              updatedFormData.client = {
                id: data.client.id || null,
                pgcCode: data.client.pgcCode || '',
                clientType: data.client.clientType || '',
                visitRange: data.client.visitRange || '',
                name: data.client.name || '',
                companyName: data.client.companyName || '',
                address: data.client.address || '',
                nif: data.client.nif || '',
                telephoneNumber: data.client.telephoneNumber || '',
                email: data.client.email || '',
                postalCode: data.client.postalCode || '',
                city: data.client.city || '',
                province: data.client.province || '',
                notes: data.client.notes || '',
              };
              setFormData(updatedFormData);
            } else if (data.clientPgcCode) {
              // Obtener datos del cliente usando 'clientPgcCode'
              const clientResponse = await axiosInstance.get(
                `/client/byCode?pgcCode=${data.clientPgcCode}`
              );
              if (clientResponse.status === 200 && clientResponse.data) {
                const clientData = clientResponse.data;
                updatedFormData.client = {
                  id: clientData.id || null,
                  pgcCode: clientData.pgcCode || '',
                  clientType: clientData.clientType || '',
                  visitRange: clientData.visitRange || '',
                  name: clientData.name || '',
                  companyName: clientData.companyName || '',
                  address: clientData.address || '',
                  nif: clientData.nif || '',
                  telephoneNumber: clientData.telephoneNumber || '',
                  email: clientData.email || '',
                  postalCode: clientData.postalCode || '',
                  city: clientData.city || '',
                  province: clientData.province || '',
                  notes: clientData.notes || '',
                };
                setFormData(updatedFormData);
              } else {
                alert('No se pudo obtener los datos del cliente.');
              }
            } else {
              alert('No se encontró información del cliente.');
            }
          }
        } catch (error) {
          console.error('Error al obtener el expediente:', error);
          alert('No se pudo cargar el expediente para edición.');
        }
      };
      fetchExpedient();
    } else {
      // Estamos creando un nuevo expediente
      const fetchLastExpedientNumber = async () => {
        try {
          const response = await axiosInstance.get('/last-expedient-number');
          if (response.status === 200) {
            const lastExpedientNumber = response.data.expedientNumber;
            setFormData((prevFormData) => ({
              ...prevFormData,
              expedientNumber: lastExpedientNumber + 1,
            }));
          }
        } catch (error) {
          console.error('Error al obtener el último número de expediente:', error);
          alert('Error al obtener el último número de expediente.');
        }
      };
      fetchLastExpedientNumber();
    }
  }, [expedientNumber]);

  // Calcular ingresos, costes y beneficio usando useMemo
  const income = useMemo(() => {
    const pax = parseFloat(formData.pax) || 0.0;
    const paxFree = parseFloat(formData.paxFree) || 0.0;
    const bonusPerPax = parseFloat(formData.bonusPerPax) || 0.0;
    const pvpPerPerson = parseFloat(formData.pvp) || 0.0;
    return pvpPerPerson * pax + bonusPerPax * paxFree;
  }, [formData.pax, formData.paxFree, formData.bonusPerPax, formData.pvp]);

  const totalCosts = useMemo(() => {
    const pax = parseFloat(formData.pax) || 0.0;
    return formData.suppliers.reduce((accumulator, supplier) => {
      const priceAdjustment = parseFloat(supplier.priceAdjust) || 0.0;
      let supplierTotal = 0.0;

      if (supplier.type === 'Restaurant' || supplier.type === 'Hotel') {
        const costPerPerson = parseFloat(supplier.costPerPerson) || 0.0;
        supplierTotal = costPerPerson * pax + priceAdjustment;
      } else {
        const cost = parseFloat(supplier.cost) || 0.0;
        supplierTotal = cost + priceAdjustment;
      }

      return accumulator + supplierTotal;
    }, 0.0);
  }, [formData.suppliers, formData.pax]);

  const benefit = useMemo(() => income - totalCosts, [income, totalCosts]);

  // Cálculo de total cobrado
  const totalCollected = useMemo(() => {
    return formData.charges.reduce((sum, charge) => sum + (parseFloat(charge.amount) || 0), 0);
  }, [formData.charges]);

  // Cálculo de total pendiente por cobrar
  const totalPendingToCollect = income - totalCollected;

  // Cálculo de total pagado a proveedores
  const totalPaidToSuppliers = useMemo(() => {
    return formData.payments.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0);
  }, [formData.payments]);

  // Cálculo de total pendiente por pagar a proveedores
  const totalPendingToPay = totalCosts - totalPaidToSuppliers;

  // Manejo de cambios en los campos del formulario (expedient y otros campos)
  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    let fieldValue = value;

    if (type === 'checkbox') {
      fieldValue = checked;
    } else if (
      [
        'pax',
        'paxFree',
        'bonusPerPax',
        'days',
        'maxPeople',
        'pvp',
        'moneyCollected',
        'moneyPending',
      ].includes(name)
    ) {
      fieldValue = parseFloat(value) || 0.0;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
  }, []);

  // Manejo de cambios en los campos del cliente
  const handleClientChange = useCallback((e) => {
    const { name, value, type } = e.target;
    let fieldValue = value;

    if (type === 'number') {
      fieldValue = parseFloat(value) || 0;
    }

    // Solo permitir cambios en 'clientType' y 'visitRange'
    if (name === 'clientType' || name === 'visitRange') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        client: {
          ...prevFormData.client,
          [name]: fieldValue,
        },
      }));
    }
  }, []);

  // Funciones para manejar proveedores
  const handleSupplierChange = useCallback((index, event) => {
    const { name, value } = event.target;
    let fieldValue = value;

    if (
      ['costPerPerson', 'priceAdjust', 'cost'].includes(name)
    ) {
      fieldValue = parseFloat(value) || 0.0;
    }

    setFormData((prevFormData) => {
      const newSuppliers = [...prevFormData.suppliers];
      newSuppliers[index][name] = fieldValue;
      return { ...prevFormData, suppliers: newSuppliers };
    });
  }, []);

  const handleSupplierTypeChange = useCallback((index, value) => {
    setFormData((prevFormData) => {
      const newSuppliers = [...prevFormData.suppliers];
      newSuppliers[index] = { ...newSuppliers[index], type: value };
      return { ...prevFormData, suppliers: newSuppliers };
    });
  }, []);

  const removeSupplier = useCallback((index) => {
    setFormData((prevFormData) => {
      const newSuppliers = prevFormData.suppliers.filter((_, i) => i !== index);
      return { ...prevFormData, suppliers: newSuppliers };
    });
  }, []);

  // Funciones para manejar los cobros (charges)
  const handleChargeChange = useCallback((index, field, value) => {
    setFormData((prevFormData) => {
      const newCharges = [...prevFormData.charges];
      newCharges[index][field] = field === 'amount' ? parseFloat(value) || 0.0 : value;
      return { ...prevFormData, charges: newCharges };
    });
  }, []);

  const addCharge = useCallback(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      charges: [
        ...prevFormData.charges,
        { amount: 0.0, date: '', method: '', description: '' },
      ],
    }));
  }, []);

  const removeCharge = useCallback((index) => {
    setFormData((prevFormData) => {
      const newCharges = prevFormData.charges.filter((_, i) => i !== index);
      return { ...prevFormData, charges: newCharges };
    });
  }, []);

  // Funciones para manejar los pagos (payments)
  const handlePaymentChange = useCallback(
    (index, field, value) => {
      setFormData((prevFormData) => {
        const newPayments = [...prevFormData.payments];

        if (field === 'amount') {
          newPayments[index][field] = parseFloat(value) || 0.0;
        } else if (field === 'supplierId') {
          const selectedSupplier = suppliersList.find(
            (s) => s.id === parseInt(value)
          );
          newPayments[index][field] = parseInt(value) || null;
          newPayments[index]['supplierName'] = selectedSupplier
            ? selectedSupplier.name
            : '';
        } else {
          newPayments[index][field] = value;
        }

        return { ...prevFormData, payments: newPayments };
      });
    },
    [suppliersList]
  );


  const addPayment = useCallback(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      payments: [
        ...prevFormData.payments,
        { supplierId: null, supplierName: '', amount: 0.0, date: '', method: '', description: '' },
      ],
    }));
  }, []);

  const removePayment = useCallback((index) => {
    setFormData((prevFormData) => {
      const newPayments = prevFormData.payments.filter((_, i) => i !== index);
      return { ...prevFormData, payments: newPayments };
    });
  }, []);

  // Función para enviar el formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.expedientNumber ||
      !formData.apertureDate ||
      !formData.groupName ||
      !formData.salesman ||
      !formData.client.pgcCode ||
      !formData.client.name ||
      !formData.client.companyName
    ) {
      alert('Por favor, completa todos los campos requeridos.');
      return;
    }

    // Mapear formData a los campos que espera el backend
    const expedientData = {
      id: formData.id,
      expedientNumber: formData.expedientNumber,
      apertureDate: formatDateForBackend(formData.apertureDate),
      closureDate: formatDateForBackend(formData.closureDate),
      entryDate: formatDateForBackend(formData.entryDate),
      departureDate: formatDateForBackend(formData.departureDate),
      situation: formData.situation,
      expedientType: formData.expedientType,
      groupName: formData.groupName,
      salesman: formData.salesman,
      destination: formData.destination,
      pax: formData.pax,
      paxFree: formData.paxFree,
      bonusPerPax: formData.bonusPerPax.toString(),
      maxPeople: formData.maxPeople,
      days: formData.days,
      guide: formData.guide,
      notes: formData.notes,
      pvp: formData.pvp.toString(),
      income: income ? income.toFixed(2) : null,
      totalCosts: totalCosts ? totalCosts.toFixed(2) : null,
      benefit: benefit ? benefit.toFixed(2) : null,
      clientPgcCode: formData.client.pgcCode,
      rooms: formData.rooms.map((room) => ({
        roomType: room.roomType,
        passengers: room.passengers.map((passenger) => ({
          fullName: passenger.fullName,
        })),
      })),
      suppliers: formData.suppliers.map((supplier) => {
        const supplierData = {
          id: supplier.supplierId,
          name: supplier.name,
          type: supplier.type,
          // Incluir los campos específicos según el tipo de proveedor
          date: supplier.date ? formatDateForBackend(supplier.date) : null,
          entryDate: supplier.entryDate ? formatDateForBackend(supplier.entryDate) : null,
          exitDate: supplier.exitDate ? formatDateForBackend(supplier.exitDate) : null,
          releaseDate: supplier.releaseDate ? formatDateForBackend(supplier.releaseDate) : null,
          costPerPerson: supplier.costPerPerson ? supplier.costPerPerson.toFixed(2) : '0.0',
          cost: supplier.cost ? supplier.cost.toFixed(2) : '0.0',
          priceAdjust: supplier.priceAdjust ? supplier.priceAdjust.toFixed(2) : '0.0',
          notes: supplier.notes || '',
          // Otros campos específicos si los hay
          entryRegimen: supplier.entryRegimen || null,
          exitRegimen: supplier.exitRegimen || null,
          totalRegimen: supplier.totalRegimen || null,
        };

        return supplierData;
      }),
      // Incluir datos de Cobros y Pagos
      charges: formData.charges.map((charge) => ({
        amount: charge.amount,
        date: formatDateForBackend(charge.date),
        method: charge.method || "unknown",
        description: charge.description,
      })),
      payments: formData.payments.map((payment) => ({
        amount: payment.amount,
        date: formatDateForBackend(payment.date),
        method: payment.method || "unknown",
        description: payment.description,
        supplierId: payment.supplierId,
        supplierName: payment.supplierName,
      })),
      moneyCollected: formData.moneyCollected.toFixed(2),
      moneyPending: formData.moneyPending.toFixed(2),
    };

    try {
      console.log('Datos enviados:', expedientData); // Para depuración
      const response = expedientNumber
        ? await axiosInstance.put('/expedient', expedientData) // Modo edición
        : await axiosInstance.post('/expedient', expedientData); // Modo nuevo

      if (response.status === 200 || response.status === 201) {
        alert(
          expedientNumber
            ? '¡Expediente actualizado exitosamente!'
            : '¡Expediente creado exitosamente!'
        );
        // Resetear el formulario si es creación
        if (!expedientNumber) {
          setFormData(initialFormData);
        }
      } else {
        alert(`Error al guardar el expediente: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error al enviar el expediente:', error);
      if (error.response) {
        alert(
          `Error al guardar el expediente: ${error.response.status} ${error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        alert('No se recibió respuesta del servidor.');
      } else {
        alert('Ocurrió un error al configurar la petición.');
      }
    }
  };

  // Funciones para la ventana flotante movible
  const handleMouseDown = useCallback(
    (e) => {
      setIsDragging(true);
      dragOffset.current = {
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      };
    },
    [position.x, position.y]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - dragOffset.current.x,
          y: e.clientY - dragOffset.current.y,
        });
      }
    },
    [isDragging]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  // Funciones para cambiar de pestaña
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // Funciones para manejar la ventana modal de búsqueda
  const openSearchModal = useCallback((type) => {
    setSearchType(type);
    setSearchQuery('');
    setSearchResults([]);
    setIsModalOpen(true);

    if (type === SEARCH_TYPES.CLIENT_NAME) {
      // Obtener todos los nombres de clientes para la búsqueda en tiempo real
      axiosInstance.get('/client/names')
        .then(response => {
          if (response.status === 200 && response.data) {
            setClientNames(response.data); // Suponiendo que response.data es un array de cadenas
          } else {
            setClientNames([]);
          }
        })
        .catch(error => {
          console.error('Error al obtener los nombres de clientes:', error);
          alert('Ocurrió un error al obtener los nombres de clientes.');
        });
    }
    // No es necesario pre-cargar datos para CLIENT_PGC o EXPEDIENT
  }, []);

  const closeSearchModal = useCallback(() => {
    setIsModalOpen(false);
    setSearchType('');
    setSearchQuery('');
    setSearchResults([]);
    setClientNames([]);
    setFilteredClientNames([]);
  }, []);

  // Función para buscar proveedores
  const handleSupplierSearch = useCallback(() => {
    if (!supplierSearchQuery.trim()) {
      alert('Por favor, ingresa un término de búsqueda.');
      return;
    }

    const query = supplierSearchQuery.toLowerCase();
    const filteredSuppliers = suppliersList.filter(
      (supplier) =>
        supplier.name.toLowerCase().includes(query) ||
        supplier.pgcCode.toString().includes(query)
    );

    setSupplierSearchResults(filteredSuppliers);
  }, [supplierSearchQuery, suppliersList]);

  // Función para seleccionar un proveedor de los resultados de búsqueda
  const handleSelectSupplier = useCallback(
    (supplier) => {
      // Crear una nueva entrada de proveedor con datos pre-llenados
      const newSupplier = {
        id: null, // ID de la relación aún no existe
        supplierId: supplier.id, // ID del proveedor seleccionado
        type: supplier.type || '',
        name: supplier.name || '',
        date: '',
        costPerPerson: 0.0,
        priceAdjust: 0.0,
        cost: 0.0,
        entryDate: '',
        entryRegimen: '',
        exitDate: '',
        exitRegimen: '',
        totalRegimen: '',
        releaseDate: '',
        notes: '',
      };

      // Dependiendo del tipo de proveedor, auto-llenar ciertos campos
      switch (supplier.type) {
        case 'Hotel':
          newSupplier.address = supplier.address || '';
          newSupplier.telephoneNumber = supplier.telephoneNumber || '';
          newSupplier.email = supplier.email || '';
          newSupplier.companyName = supplier.companyName || '';
          newSupplier.contactPerson = supplier.contactPerson || '';
          newSupplier.charge = supplier.charge || '';
          break;
        case 'Restaurant':
          newSupplier.address = supplier.address || '';
          newSupplier.telephoneNumber = supplier.telephoneNumber || '';
          newSupplier.email = supplier.email || '';
          newSupplier.companyName = supplier.companyName || '';
          newSupplier.contactPerson = supplier.contactPerson || '';
          newSupplier.charge = supplier.charge || '';
          break;
        case 'Transport':
          newSupplier.address = supplier.address || '';
          newSupplier.telephoneNumber = supplier.telephoneNumber || '';
          newSupplier.email = supplier.email || '';
          newSupplier.companyName = supplier.companyName || '';
          newSupplier.contactPerson = supplier.contactPerson || '';
          newSupplier.charge = supplier.charge || '';
          break;
        // Agrega más casos según los tipos de proveedores que tengas
        default:
          break;
      }

      // Añadir el nuevo proveedor al estado del formulario
      setFormData((prevFormData) => ({
        ...prevFormData,
        suppliers: [...prevFormData.suppliers, newSupplier],
      }));

      // Cerrar el modal de búsqueda
      setIsSupplierModalOpen(false);

      // Resetear los estados de búsqueda
      setSupplierSearchQuery('');
      setSupplierSearchResults([]);
    },
    []
  );

  // Funciones para manejar la ventana modal de búsqueda de proveedores
  const openSupplierModal = useCallback(() => {
    setIsSupplierModalOpen(true);
    setSupplierSearchQuery('');
    setSupplierSearchResults([]);
  }, []);

  const closeSupplierModal = useCallback(() => {
    setIsSupplierModalOpen(false);
    setSupplierSearchQuery('');
    setSupplierSearchResults([]);
  }, []);


  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      alert('Por favor, ingresa un término de búsqueda.');
      return;
    }

    try {
      let response;
      if (searchType === SEARCH_TYPES.EXPEDIENT) {
        response = await axiosInstance.get(`/expedient?expedientNumber=${encodeURIComponent(searchQuery)}`);
        if (response.status === 200 && response.data) {
          console.log('Expedient Data:', response.data);
          setSearchResults([response.data]);
        } else {
          setSearchResults([]);
        }
      } else if (searchType === SEARCH_TYPES.CLIENT_PGC) {
        response = await axiosInstance.get(`/client/byCode?pgcCode=${encodeURIComponent(searchQuery)}`);
        if (response.status === 200 && response.data) {
          setSearchResults([response.data]); // Suponiendo que response.data es un objeto cliente
        } else {
          setSearchResults([]);
        }
      }
      // No se realiza búsqueda aquí para CLIENT_NAME, ya que se maneja en tiempo real
    } catch (error) {
      console.error('Error durante la búsqueda:', error);
      alert('Ocurrió un error durante la búsqueda.');
    }
  };

  const handleSelectResult = useCallback(async (result) => {
    if (searchType === SEARCH_TYPES.CLIENT_NAME) {
      try {
        // Realizar una petición GET para obtener los datos completos del cliente por nombre
        const response = await axiosInstance.get(`/client/byName?name=${encodeURIComponent(result)}`);
        if (response.status === 200 && response.data) {
          const clientData = response.data;
          setFormData((prevFormData) => ({
            ...prevFormData,
            client: {
              id: clientData.id || null,
              pgcCode: clientData.pgcCode || '',
              clientType: clientData.clientType || '',
              visitRange: clientData.visitRange || '',
              name: clientData.name || '',
              companyName: clientData.companyName || '',
              address: clientData.address || '',
              nif: clientData.nif || '',
              telephoneNumber: clientData.telephoneNumber || '',
              email: clientData.email || '',
              postalCode: clientData.postalCode || '',
              city: clientData.city || '',
              province: clientData.province || '',
              notes: clientData.notes || '',
            },
          }));
        } else {
          alert('No se pudo obtener los datos del cliente seleccionado.');
        }
      } catch (error) {
        console.error('Error al obtener los datos del cliente:', error);
        alert('Ocurrió un error al obtener los datos del cliente.');
      }
    } else if (searchType === SEARCH_TYPES.CLIENT_PGC || searchType === SEARCH_TYPES.EXPEDIENT) {
      // Para CLIENT_PGC y EXPEDIENT, los resultados ya contienen los datos completos
      if (searchType === SEARCH_TYPES.CLIENT_PGC) {
        const clientData = result; // Suponiendo que result es un objeto cliente
        setFormData((prevFormData) => ({
          ...prevFormData,
          client: {
            id: clientData.id || null,
            pgcCode: clientData.pgcCode || '',
            clientType: clientData.clientType || '',
            visitRange: clientData.visitRange || '',
            name: clientData.name || '',
            companyName: clientData.companyName || '',
            address: clientData.address || '',
            nif: clientData.nif || '',
            telephoneNumber: clientData.telephoneNumber || '',
            email: clientData.email || '',
            postalCode: clientData.postalCode || '',
            city: clientData.city || '',
            province: clientData.province || '',
            notes: clientData.notes || '',
          },
        }));
      } else if (searchType === SEARCH_TYPES.EXPEDIENT) {
        const expedientData = result; // Suponiendo que result es un objeto expediente
        const updatedFormData = {
          expedientNumber: expedientData.expedientNumber || '',
          apertureDate: expedientData.apertureDate ? formatDateForInput(expedientData.apertureDate) : '',
          closureDate: expedientData.closureDate ? formatDateForInput(expedientData.closureDate) : '',
          entryDate: expedientData.entryDate ? formatDateForInput(expedientData.entryDate) : '',
          departureDate: expedientData.departureDate ? formatDateForInput(expedientData.departureDate) : '',
          groupName: expedientData.groupName || '',
          salesman: expedientData.salesman || '',
          destination: expedientData.destination || '',
          pax: parseFloat(expedientData.pax) || 0,
          paxFree: parseFloat(expedientData.paxFree) || 0,
          bonusPerPax: parseFloat(expedientData.bonusPerPax) || 0.0,
          maxPeople: parseFloat(expedientData.maxPeople) || 0,
          days: parseFloat(expedientData.days) || 0,
          guide: expedientData.guide || '',
          notes: expedientData.notes || '',
          pvp: parseFloat(expedientData.pvp) || 0.0,
          income: parseFloat(expedientData.income) || 0.0,
          totalCosts: parseFloat(expedientData.totalCosts) || 0.0,
          benefit: parseFloat(expedientData.benefit) || 0.0,
          client: {
            id: null,
            pgcCode: '',
            clientType: '',
            visitRange: '',
            name: '',
            companyName: '',
            address: '',
            nif: '',
            telephoneNumber: '',
            email: '',
            postalCode: '',
            city: '',
            province: '',
            notes: '',
          },
          rooms: expedientData.rooms.map((room) => ({
            roomType: room.roomType,
            passengers: room.passengers.map((passenger) => ({
              fullName: passenger.fullName,
            })),
          })) || [],
          suppliers:
            expedientData.suppliers.map((supplier) => ({
              ...supplier,
              priceAdjust: parseFloat(supplier.priceAdjust) || 0.0,
              costPerPerson: parseFloat(supplier.costPerPerson) || 0.0,
              cost: parseFloat(supplier.cost) || 0.0,
              date: supplier.date ? formatDateForInput(supplier.date) : '',
              entryDate: supplier.entryDate ? formatDateForInput(supplier.entryDate) : '',
              exitDate: supplier.exitDate ? formatDateForInput(supplier.exitDate) : '',
              releaseDate: supplier.releaseDate ? formatDateForInput(supplier.releaseDate) : '',
            })) || [],
          charges: expedientData.charges.map((charge) => ({
            amount: parseFloat(charge.amount) || 0.0,
            date: charge.date ? formatDateForInput(charge.date) : '',
            method: charge.method || '',
            description: charge.description || '',
          })) || [],
          payments: expedientData.payments.map((payment) => ({
            amount: parseFloat(payment.amount) || 0.0,
            date: payment.date ? formatDateForInput(payment.date) : '',
            method: payment.method || '',
            description: payment.description || '',
            supplierId: payment.supplierId || null,
            supplierName: payment.supplierName || '',
          })) || [],
          moneyCollected: parseFloat(expedientData.moneyCollected) || 0.0,
          moneyPending: parseFloat(expedientData.moneyPending) || 0.0,
        };

        if (expedientData.client) {
          updatedFormData.client = {
            id: expedientData.client.id || null,
            pgcCode: expedientData.client.pgcCode || '',
            clientType: expedientData.client.clientType || '',
            visitRange: expedientData.client.visitRange || '',
            name: expedientData.client.name || '',
            companyName: expedientData.client.companyName || '',
            address: expedientData.client.address || '',
            nif: expedientData.client.nif || '',
            telephoneNumber: expedientData.client.telephoneNumber || '',
            email: expedientData.client.email || '',
            postalCode: expedientData.client.postalCode || '',
            city: expedientData.client.city || '',
            province: expedientData.client.province || '',
            notes: expedientData.client.notes || '',
          };
          setFormData(updatedFormData);
        } else if (expedientData.clientPgcCode) {
          // Obtener datos del cliente usando 'clientPgcCode'
          try {
            const clientResponse = await axiosInstance.get(
              `/client/byCode?pgcCode=${expedientData.clientPgcCode}`
            );
            if (clientResponse.status === 200 && clientResponse.data) {
              const clientData = clientResponse.data;
              updatedFormData.client = {
                id: clientData.id || null,
                pgcCode: clientData.pgcCode || '',
                clientType: clientData.clientType || '',
                visitRange: clientData.visitRange || '',
                name: clientData.name || '',
                companyName: clientData.companyName || '',
                address: clientData.address || '',
                nif: clientData.nif || '',
                telephoneNumber: clientData.telephoneNumber || '',
                email: clientData.email || '',
                postalCode: clientData.postalCode || '',
                city: clientData.city || '',
                province: clientData.province || '',
                notes: clientData.notes || '',
              };
              setFormData(updatedFormData);
            } else {
              alert('No se pudo obtener los datos del cliente.');
            }
          } catch (error) {
            console.error('Error al obtener los datos del cliente:', error);
            alert('Ocurrió un error al obtener los datos del cliente.');
          }
        } else {
          alert('No se encontró información del cliente en el expediente seleccionado.');
        }
      }
    }
    closeSearchModal();
  }, [closeSearchModal, searchType]);

  // Calcular total de cobros (charges)
  const totalChargesAmount = useMemo(() => {
    return formData.charges.reduce(
      (sum, charge) => sum + (parseFloat(charge.amount) || 0),
      0
    );
  }, [formData.charges]);

  // Calcular total de pagos (payments)
  const totalPaymentsAmount = useMemo(() => {
    return formData.payments.reduce(
      (sum, payment) => sum + (parseFloat(payment.amount) || 0),
      0
    );
  }, [formData.payments]);

  return (
    <div className={styles.travelForm}>
      {/* Navegación de Pestañas */}
      <div className={styles.tabNavigation}>
        <button
          className={`${styles['tabButton']} ${activeTab === 'Expedient' ? styles.active : ''}`}
          onClick={() => handleTabClick('Expedient')}
        >
          Expediente
        </button>
        <button
          className={`${styles['tabButton']} ${activeTab === 'Services' ? styles.active : ''}`}
          onClick={() => handleTabClick('Services')}
        >
          Servicios
        </button>
        {/* Pestaña "Caja" */}
        <button
          className={`${styles['tabButton']} ${activeTab === 'CashRegister' ? styles.active : ''}`}
          onClick={() => handleTabClick('CashRegister')}
        >
          Caja
        </button>
        {/* Nueva pestaña "Rooming" */}
        <button
          className={`${styles['tabButton']} ${activeTab === 'Rooming' ? styles.active : ''}`}
          onClick={() => handleTabClick('Rooming')}
        >
          Rooming
        </button>
        {/* Nueva pestaña "Resumen" */}
        <button
          className={`${styles['tabButton']} ${activeTab === 'Resumen' ? styles.active : ''}`}
          onClick={() => handleTabClick('Resumen')}
        >
          Resumen
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Sección Expediente */}
        {activeTab === 'Expedient' && (
          <ExpedientTab
            formData={formData}
            handleInputChange={handleInputChange}
            handleClientChange={handleClientChange}
            openSearchModal={openSearchModal}
          />
        )}

        {/* Sección Servicios */}
        {activeTab === 'Services' && (
          <>
            {/* Componente Proveedores */}
            <div className={styles.card}>
              <h2>{FORM_LABELS.suppliers}</h2>
              {formData.suppliers.map((supplier, index) => (
                <SupplierFields
                  key={index}
                  supplier={supplier}
                  index={index}
                  handleSupplierChange={handleSupplierChange}
                  handleSupplierTypeChange={handleSupplierTypeChange}
                  removeSupplier={removeSupplier}
                />
              ))}
              <button type="button" className={styles.addButton} onClick={openSupplierModal}>
                Añadir Proveedor
              </button>
            </div>

            {/* Componente Información Adicional */}
            <AdditionalInfo formData={formData} handleInputChange={handleInputChange} />
          </>
        )}

        {/* Sección Caja */}
        {activeTab === 'CashRegister' && (
          <CajaTab
            charges={formData.charges}
            payments={formData.payments}
            handleChargeChange={handleChargeChange}
            handlePaymentChange={handlePaymentChange}
            addCharge={addCharge}
            removeCharge={removeCharge}
            addPayment={addPayment}
            removePayment={removePayment}
            moneyCollected={formData.moneyCollected}
            moneyPending={formData.moneyPending}
            handleInputChange={handleInputChange}
            suppliersList={formData.suppliers}
            totalCollected={totalCollected}
            totalPendingToCollect={totalPendingToCollect}
            totalPaidToSuppliers={totalPaidToSuppliers}
            totalPendingToPay={totalPendingToPay}
          />
        )}

        {/* Contenido de la pestaña "Resumen" */}
        {activeTab === 'Resumen' && (
          <SummaryTab
            formData={formData}
            suppliersList={suppliersList}
            income={income}
            totalCosts={totalCosts}
            benefit={benefit}
            totalCollected={totalCollected}
            totalChargesAmount={totalChargesAmount}
            totalPaymentsAmount={totalPaymentsAmount}
            totalPendingToCollect={totalPendingToCollect}
            totalPendingToPay={totalPendingToPay}
          />
        )}

        {/* Nueva pestaña "Rooming" */}
        {activeTab === 'Rooming' && (
          <RoomingTab formData={formData} setFormData={setFormData} />
        )}

        {/* Botones */}
        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.submitButton}>
            {expedientNumber ? 'Guardar edición' : 'Guardar nuevo expediente'}
          </button>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={() => navigate('/malaka/expedients')}
          >
            Cancelar
          </button>
        </div>
      </form>

      {/* Ventana flotante para el beneficio, ingresos y costes */}
      <FloatingWindow
        position={position}
        handleMouseDown={handleMouseDown}
        income={income}
        totalCosts={totalCosts}
        benefit={benefit}
        totalCollected={totalCollected}
        totalPendingToCollect={totalPendingToCollect}
        totalPaidToSuppliers={totalPaidToSuppliers}
        totalPendingToPay={totalPendingToPay}
      />

      {/* Ventana Modal para Búsqueda de Clientes */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeSearchModal}
        contentLabel="Búsqueda"
        className={styles.modal}
        overlayClassName="overlay"
      >
        <h2>
          {searchType === SEARCH_TYPES.EXPEDIENT
            ? 'Buscar Expediente'
            : searchType === SEARCH_TYPES.CLIENT_NAME
              ? 'Buscar Cliente por Nombre'
              : 'Buscar Cliente por Código PGC'}
        </h2>
        <div className={styles.modalContent}>
          {searchType === SEARCH_TYPES.EXPEDIENT ? (
            <>
              <input
                type="text"
                placeholder="Número de Expediente"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <button type="button" onClick={handleSearch}>
                Buscar
              </button>
            </>
          ) : searchType === SEARCH_TYPES.CLIENT_NAME ? (
            <>
              <input
                type="text"
                placeholder="Buscar Nombre del Cliente"
                value={searchQuery}
                onChange={(e) => handleSearchInputChange(e.target.value)}
              />
            </>
          ) : searchType === SEARCH_TYPES.CLIENT_PGC ? (
            <>
              <input
                type="text"
                placeholder="Buscar Código PGC del Cliente"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <button type="button" onClick={handleSearch}>
                Buscar
              </button>
            </>
          ) : null}
        </div>
        <div className={styles.searchResults}>
          {searchType === SEARCH_TYPES.EXPEDIENT && searchResults && searchResults.length > 0 ? (
            <ul>
              {searchResults.map((expedient) => (
                <li key={expedient.id} onClick={() => handleSelectResult(expedient)}>
                  Expediente #{expedient.expedientNumber} - {expedient.groupName}
                </li>
              ))}
            </ul>
          ) : searchType === SEARCH_TYPES.CLIENT_NAME && filteredClientNames && filteredClientNames.length > 0 ? (
            <ul>
              {filteredClientNames.map((clientName, index) => (
                <li key={`${clientName}-${index}`} onClick={() => handleSelectResult(clientName)}>
                  {clientName}
                </li>
              ))}
            </ul>
          ) : searchType === SEARCH_TYPES.CLIENT_PGC && searchResults && searchResults.length > 0 ? (
            <ul>
              {searchResults.map((client) => (
                <li key={client.id} onClick={() => handleSelectResult(client)}>
                  {client.pgcCode} - {client.name} - {client.companyName}
                </li>
              ))}
            </ul>
          ) : (
            <p>No se encontraron resultados.</p>
          )}
        </div>
        <button className={styles.closeButton} onClick={closeSearchModal}>
          Cerrar
        </button>
      </Modal>

      {/* Ventana Modal para Búsqueda de Proveedores */}
      <Modal
        isOpen={isSupplierModalOpen}
        onRequestClose={closeSupplierModal}
        contentLabel="Buscar Proveedor"
        className={styles.modal}
        overlayClassName="overlay"
      >
        <h2>Buscar Proveedor</h2>
        <div className={styles.modalContent}>
          <input
            type="text"
            placeholder="Buscar por nombre o código PGC"
            value={supplierSearchQuery}
            onChange={(e) => {
              setSupplierSearchQuery(e.target.value);
              handleSupplierSearchInputChange(e.target.value);
            }}
          />
          {/* Opcional: Eliminar el botón "Buscar" ya que la búsqueda es en directo */}
          {/* <button type="button" onClick={handleSupplierSearch}>
      Buscar
    </button> */}
        </div>
        <div className={styles.searchResults}>
          {supplierSearchResults && supplierSearchResults.length > 0 ? (
            <ul>
              {supplierSearchResults.map((supplier) => (
                <li key={supplier.id} onClick={() => handleSelectSupplier(supplier)}>
                  {supplier.name} - {supplier.type}
                </li>
              ))}
            </ul>
          ) : (
            <p>No se encontraron proveedores.</p>
          )}
        </div>
        <button className={styles.closeButton} onClick={closeSupplierModal}>
          Cerrar
        </button>
      </Modal>
    </div>
  );
}

export default TravelForm;