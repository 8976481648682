import React, { useEffect, useState } from 'react';
import styles from '../../styles/Malaka/TravelForm.module.css';
import { FaSearch } from 'react-icons/fa';
import { SEARCH_TYPES } from '../../utils/constants';
import axiosInstance from '../../services/axiosConfig';

// Componente Expedient
const Expedient = ({ formData, handleInputChange, openSearchModal }) => {
    // Estado local para almacenar la lista de vendedores
    const [salesmanList, setSalesmenList] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga
    const [error, setError] = useState(null); // Estado para manejar errores

    // useEffect para obtener la lista de vendedores desde el backend
    useEffect(() => {
        const fetchSalesmen = async () => {
            try {
                const response = await axiosInstance.get('/salesmen');
                // Acceder a la propiedad 'salesmen' del objeto de respuesta
                setSalesmenList(response.data.salesmen);
                setIsLoading(false);
            } catch (err) {
                console.error('Error al obtener la lista de vendedores:', err);
                setError('No se pudo cargar la lista de vendedores.');
                setIsLoading(false);
            }
        };

        fetchSalesmen();
    }, []);

    // Opcional: Establecer un valor predeterminado para "Vendedor" si no está seleccionado
    useEffect(() => {
        if (!formData.salesman && salesmanList.length > 0) {
            handleInputChange({
                target: { name: 'salesman', value: salesmanList[0] }
            });
        }
    }, [salesmanList, formData.salesman, handleInputChange]);

    return (
        <div className={`${styles.card} ${styles.compact}`}>
            <h2>Expediente</h2>

            {/* Primera Fila con 4 Columnas */}
            <div className={styles.expedientGridRow4}>
                <div className={`${styles.formGroup} ${styles.smallField}`}>
                    <label htmlFor="expedientNumber">Número</label>
                    <input
                        id="expedientNumber"
                        type="text"
                        name="expedientNumber"
                        value={formData.expedientNumber}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="groupName">Nombre del Grupo</label>
                    <input
                        id="groupName"
                        type="text"
                        name="groupName"
                        value={formData.groupName}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="destination">Destino</label>
                    <input
                        id="destination"
                        type="text"
                        name="destination"
                        value={formData.destination}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={styles.pvpInput}>
                    <label htmlFor="pvp">{"PVP"}</label>
                    <input
                        id="pvp"
                        type="number"
                        name="pvp"
                        value={formData.pvp}
                        onChange={handleInputChange}
                        step="0.01"
                    />
                </div>
            </div>

            {/* Segunda Fila con 3 Columnas */}
            <div className={styles.expedientGridRow3}>
                <div className={styles.formGroup}>
                    <label htmlFor="entryDate">Fecha de Entrada</label>
                    <input
                        id="entryDate"
                        type="date"
                        name="entryDate"
                        value={formData.entryDate}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="departureDate">Fecha de Salida</label>
                    <input
                        id="departureDate"
                        type="date"
                        name="departureDate"
                        value={formData.departureDate}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="days">Días</label>
                    <input
                        id="days"
                        type="number"
                        name="days"
                        value={formData.days}
                        onChange={handleInputChange}
                        step="1"
                        className={styles.smallField}
                    />
                </div>
            </div>

            {/* Tercera Fila con 5 Columnas: Vendedor, Fecha de Apertura, Fecha de Cierre, Situación, Tipo de Expediente */}
            <div className={styles.expedientGridRow5}>
                {/* Vendedor: Dropdown */}
                <div className={styles.formGroup}>
                    <label htmlFor="salesman">Vendedor</label>
                    {isLoading ? (
                        <p>Cargando vendedores...</p>
                    ) : error ? (
                        <p className={styles.errorText}>{error}</p>
                    ) : (
                        <select
                            id="salesman"
                            name="salesman"
                            value={formData.salesman}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Seleccione un vendedor</option>
                            {salesmanList.map((salesman, index) => (
                                <option key={index} value={salesman}>
                                    {salesman}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="apertureDate">Fecha de Apertura</label>
                    <input
                        id="apertureDate"
                        type="date"
                        name="apertureDate"
                        value={formData.apertureDate}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="closureDate">Fecha de Cierre</label>
                    <input
                        id="closureDate"
                        type="date"
                        name="closureDate"
                        value={formData.closureDate}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="situation">Situación</label>
                    <select
                        id="situation"
                        name="situation"
                        value={formData.situation}
                        onChange={handleInputChange}
                        required
                    >
                        {/*<option value="">Seleccione una opción</option>*/}
                        <option value="neutral">Neutral</option>
                        <option value="nil">Nulo</option>
                        <option value="allServicesDelivered">Entregados todos los servicios</option>
                    </select>
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="expedientType">Tipo de Expediente</label>
                    <select
                        id="expedientType"
                        name="expedientType"
                        value={formData.expedientType}
                        onChange={handleInputChange}
                        className={styles.expedientTypeSelect} // Clase para ancho personalizado
                        required
                    >
                        {/*<option value="">Seleccione una opción</option>*/}
                        <option value="homogeneousGroup">Grupo Homogéneo</option>
                        <option value="seatToSeat">Plaza a Plaza</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

// Componente ClientData
const ClientData = ({ formData, handleClientChange, openSearchModal }) => {
    return (
        <div className={`${styles.card} ${styles.compact}`}>
            <h2>Datos del Cliente</h2>

            {/* Primera Fila con 6 Columnas */}
            <div className={styles.clientGridRow6}>
                {/* Campo Código PGC */}
                <div className={styles.formGroup}>
                    <label htmlFor="pgcCode">Código PGC</label>
                    <div className={styles.inputWithIcon}>
                        <input
                            id="pgcCode"
                            type="text"
                            name="pgcCode"
                            value={formData.client.pgcCode}
                            onChange={handleClientChange}
                            readOnly // Campo de solo lectura
                        />
                        <FaSearch
                            className={styles.travelFormSearchIcon}
                            onClick={() => openSearchModal(SEARCH_TYPES.CLIENT_PGC)}
                            aria-label="Buscar Cliente por Código PGC"
                            title="Buscar Cliente por Código PGC"
                        />
                    </div>
                </div>

                {/* Campo Tipo de Cliente */}
                <div className={styles.formGroup}>
                    <label htmlFor="clientType">Tipo de Cliente</label>
                    <input
                        id="clientType"
                        name="clientType"
                        value={formData.client.clientType}
                        readOnly
                    />
                </div>

                {/* Campo Calidad del Cliente */}
                <div className={styles.formGroup}>
                    <label htmlFor="visitRange">Rango de visitas</label>
                    <input
                        id="visitRange"
                        name="visitRange"
                        value={formData.client.visitRange}
                        readOnly // Campo de solo lectura
                    />
                </div>

                {/* Campo Nombre del Cliente */}
                <div className={styles.formGroup}>
                    <label htmlFor="name">Nombre</label>
                    <div className={styles.inputWithIcon}>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={formData.client.name}
                            readOnly // Campo de solo lectura
                        />
                        <FaSearch
                            className={styles.travelFormSearchIcon}
                            onClick={() => openSearchModal(SEARCH_TYPES.CLIENT_NAME)}
                            aria-label="Buscar Cliente por Nombre"
                            title="Buscar Cliente por Nombre"
                        />
                    </div>
                </div>

                {/* Campo Razón Social */}
                <div className={styles.formGroup}>
                    <label htmlFor="companyName">Razón Social</label>
                    <input
                        id="companyName"
                        type="text"
                        name="companyName"
                        value={formData.client.companyName}
                        readOnly
                    />
                </div>

                {/* Campo Dirección */}
                <div className={styles.formGroup}>
                    <label htmlFor="address">Dirección</label>
                    <input
                        id="address"
                        type="text"
                        name="address"
                        value={formData.client.address}
                        readOnly
                    />
                </div>
            </div>

            {/* Segunda Fila con 6 Columnas */}
            <div className={styles.clientGridRow6}>
                {/* Campo NIF */}
                <div className={styles.formGroup}>
                    <label htmlFor="nif">NIF</label>
                    <input
                        id="nif"
                        type="text"
                        name="nif"
                        value={formData.client.nif}
                        readOnly
                    />
                </div>

                {/* Campo Teléfono */}
                <div className={styles.formGroup}>
                    <label htmlFor="telephoneNumber">Teléfono</label>
                    <input
                        id="telephoneNumber"
                        type="text"
                        name="telephoneNumber"
                        value={formData.client.telephoneNumber}
                        readOnly
                    />
                </div>

                {/* Campo Email */}
                <div className={styles.formGroup}>
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={formData.client.email}
                        readOnly
                    />
                </div>

                {/* Campo Código Postal */}
                <div className={styles.formGroup}>
                    <label htmlFor="postalCode">Código Postal</label>
                    <input
                        id="postalCode"
                        type="text"
                        name="postalCode"
                        value={formData.client.postalCode}
                        readOnly
                    />
                </div>

                {/* Campo Localidad */}
                <div className={styles.formGroup}>
                    <label htmlFor="city">Localidad</label>
                    <input
                        id="city"
                        type="text"
                        name="city"
                        value={formData.client.city}
                        readOnly
                    />
                </div>

                {/* Campo Provincia */}
                <div className={styles.formGroup}>
                    <label htmlFor="province">Provincia</label>
                    <input
                        id="province"
                        type="text"
                        name="province"
                        value={formData.client.province}
                        readOnly
                    />
                </div>
            </div>

            {/* Fila Única para el Campo Notas */}
            <div className={styles.clientNotesRow}>
                <label htmlFor="notes">Notas</label>
                <textarea
                    id="notes"
                    name="notes"
                    value={formData.client.notes}
                    readOnly
                />
            </div>

            {/* Nota para la sección */}
            <p className={styles.clientDataNote}>
                * Los datos del cliente no se pueden modificar aquí. Para editar la información del cliente, dirígete a la sección correspondiente.
            </p>
        </div>
    );
};

// Componente ExpedientTab que engloba Expedient y ClientData
const ExpedientTab = ({ formData, handleInputChange, handleClientChange, openSearchModal }) => {
    return (
        <>
            <Expedient
                formData={formData}
                handleInputChange={handleInputChange}
                openSearchModal={openSearchModal}
            />
            <ClientData
                formData={formData}
                handleClientChange={handleClientChange}
                openSearchModal={openSearchModal}
            />
        </>
    );
};

export default ExpedientTab;