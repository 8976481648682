// src/components/SupplierForm.js

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import styles from '../../styles/Malaka/SupplierForm.module.css'; // Importa los estilos como módulo

function SupplierForm() {
  const { name } = useParams();
  const navigate = useNavigate();
  const isEditMode = Boolean(name);

  const [formData, setFormData] = useState({
    name: '',
    type: '',
    pgcCode: '',
    bankAccount: '',
    nif: '',
    contactPerson: '',
    charge: '',
    telephoneNumber: '',
    companyName: '',
    address: '',
    postalCode: '',
    city: '',
    province: '',
    country: '',
    email: '',
    notes: '',
  });

  const [isLoading, setIsLoading] = useState(isEditMode);
  const [error, setError] = useState(null);

  // Estados para expedientes
  const [expedients, setExpedients] = useState([]);
  const [isExpedientsLoading, setIsExpedientsLoading] = useState(false);
  const [expedientsError, setExpedientsError] = useState(null);

  // Estado para manejar las tarjetas desplegables
  const [expandedExpedients, setExpandedExpedients] = useState({});

  // Estado para controlar la pestaña activa
  const [activeTab, setActiveTab] = useState('Formulario'); // 'Formulario' o 'Expedientes'

  useEffect(() => {
    if (isEditMode) {
      const fetchSupplier = async () => {
        try {
          const response = await axiosInstance.get('/supplier/byName', {
            params: { name },
          });
          if (response.status === 200 && response.data) {
            setFormData({
              id: response.data.id || '',
              name: response.data.name || '',
              type: response.data.type || '',
              pgcCode: response.data.pgcCode || '',
              bankAccount: response.data.bankAccount || '',
              nif: response.data.nif || '',
              contactPerson: response.data.contactPerson || '',
              charge: response.data.charge || '',
              telephoneNumber: response.data.telephoneNumber || '',
              companyName: response.data.companyName || '',
              address: response.data.address || '',
              postalCode: response.data.postalCode || '',
              city: response.data.city || '',
              province: response.data.province || '',
              country: response.data.country || '',
              email: response.data.email || '',
              notes: response.data.notes || '',
            });

            // Obtener los expedientes del proveedor
            if (response.data.id) {
              fetchSupplierExpedients(response.data.id);
            }
          } else {
            setError('No se pudo obtener los datos del proveedor.');
          }
        } catch (err) {
          console.error('Error al obtener el proveedor:', err);
          setError('Ocurrió un error al obtener los datos del proveedor.');
        } finally {
          setIsLoading(false);
        }
      };

      fetchSupplier();
    } else {
      const fetchLastSupplierPgcCode = async () => {
        try {
          const response = await axiosInstance.get('/last-expedient-number');
          if (response.status === 200) {
            const lastPgcCode = response.data.supplierPgcCode;
            setFormData((prevData) => ({
              ...prevData,
              pgcCode: lastPgcCode + 1,
            }));
          }
        } catch (error) {
          console.error('Error al obtener el último número de expediente:', error);
          alert('Error al obtener el último número de expediente.');
        }
      };
      fetchLastSupplierPgcCode();
    }
  }, [isEditMode, name]);

  // Función para obtener los expedientes del proveedor
  const fetchSupplierExpedients = async (supplierID) => {
    setIsExpedientsLoading(true);
    try {
      const response = await axiosInstance.get('/supplierExpedient', {
        params: { supplierID },
      });
      if (response.status === 200 && Array.isArray(response.data)) {
        setExpedients(response.data);
      } else {
        setExpedientsError('No se pudieron obtener los expedientes del proveedor.');
      }
    } catch (err) {
      console.error('Error al obtener los expedientes:', err);
      setExpedientsError('Ocurrió un error al obtener los expedientes del proveedor.');
    } finally {
      setIsExpedientsLoading(false);
    }
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.type || !formData.pgcCode) {
      alert('Por favor, completa los campos requeridos (Nombre, Tipo, Código PGC).');
      return;
    }

    try {
      if (isEditMode) {
        const response = await axiosInstance.put('/supplier', formData);
        if (response.status === 200 || response.status === 201) {
          alert('¡Proveedor actualizado exitosamente!');
          navigate('/malaka/suppliers');
        } else {
          alert(`Error al actualizar el proveedor: ${response.status} ${response.statusText}`);
        }
      } else {
        const response = await axiosInstance.post('/supplier', formData);
        if (response.status === 200 || response.status === 201) {
          alert('¡Proveedor creado exitosamente!');
          navigate('/malaka/suppliers');
        } else {
          alert(`Error al crear el proveedor: ${response.status} ${response.statusText}`);
        }
      }
    } catch (err) {
      console.error('Error al enviar el formulario:', err);
      console.log('formData', formData);
      if (err.response) {
        alert(`Error: ${err.response.data.message || err.response.statusText}`);
      } else if (err.request) {
        alert('No se recibió respuesta del servidor.');
      } else {
        alert('Ocurrió un error al configurar la petición.');
      }
    }
  };

  const handleCancel = () => {
    navigate('/malaka/suppliers');
  };

  const handleViewExpedient = (expedientNumber) => {
    navigate(`/malaka/expedients/edit/${expedientNumber}`);
  };

  const toggleExpedient = (expedientNumber) => {
    setExpandedExpedients((prevState) => ({
      ...prevState,
      [expedientNumber]: !prevState[expedientNumber],
    }));
  };

  if (isLoading) {
    return (
      <div className={styles.supplierForm}>
        <p>Cargando proveedor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.supplierForm}>
        <p className={styles.error}>{error}</p>
      </div>
    );
  }

  return (
    <div className={styles.supplierForm}>
      <h1>{isEditMode ? 'Editar Proveedor' : 'Crear Nuevo Proveedor'}</h1>

      {/* Navegación de Pestañas */}
      <div className={styles.tabNavigation}>
        <button
          className={`${styles.tabButton} ${activeTab === 'Formulario' ? styles.active : ''}`}
          onClick={() => setActiveTab('Formulario')}
          aria-label="Pestaña Formulario"
        >
          Formulario
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'Expedientes' ? styles.active : ''}`}
          onClick={() => setActiveTab('Expedientes')}
          aria-label="Pestaña Expedientes"
        >
          Expedientes asociados
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Contenido Basado en la Pestaña Activa */}
        {activeTab === 'Formulario' && (
          <div className={styles.card}>
            <h2>Datos del Proveedor</h2>

            {/* Primera fila */}
            <div className={`${styles.formRow} ${styles.leftAlign}`}>
              <div className={styles.formGroup}>
                <label htmlFor="name">Nombre*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="type">Tipo*</label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccionar...</option>
                  <option value="Restaurant">Restaurante</option>
                  <option value="Transport">Transporte</option>
                  <option value="Others">Otros</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Guide">Guía</option>
                </select>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="pgcCode">Código PGC*</label>
                <input
                  type="text"
                  id="pgcCode"
                  name="pgcCode"
                  value={formData.pgcCode}
                  onChange={handleChange}
                  required
                  disabled={isEditMode} // Deshabilitar para edición si es necesario
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="nif">NIF*</label>
                <input
                  type="text"
                  id="nif"
                  name="nif"
                  value={formData.nif}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Segunda fila */}
            <div className={`${styles.formRow} ${styles.leftAlign}`}>
              <div className={styles.formGroup}>
                <label htmlFor="contactPerson">Persona de Contacto</label>
                <input
                  type="text"
                  id="contactPerson"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="charge">Cargo</label>
                <input
                  type="text"
                  id="charge"
                  name="charge"
                  value={formData.charge}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="telephoneNumber">Teléfono</label>
                <input
                  type="text"
                  id="telephoneNumber"
                  name="telephoneNumber"
                  value={formData.telephoneNumber}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="companyName">Razón Social</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Tercera fila */}
            <div className={`${styles.formRow} ${styles.leftAlign}`}>
              <div className={styles.formGroup}>
                <label htmlFor="address">Dirección</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="postalCode">Código Postal</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="city">Localidad</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="province">Provincia</label>
                <input
                  type="text"
                  id="province"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Cuarta fila */}
            <div className={`${styles.formRow} ${styles.leftAlign}`}>
              <div className={styles.formGroup}>
                <label htmlFor="country">País</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="bankAccount">Cuenta bancaria</label>
                <input
                  type="text"
                  id="bankAccount"
                  name="bankAccount"
                  value={formData.bankAccount}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Campo Notas */}
            <div className={`${styles.formRow}`}>
              <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                <label htmlFor="notes">Notas</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}

        {activeTab === 'Expedientes' && (
          <div className={styles.card}>
            <h2>Expedientes Asociados</h2>

            {isExpedientsLoading ? (
              <p>Cargando expedientes...</p>
            ) : expedientsError ? (
              <p className={styles.error}>{expedientsError}</p>
            ) : expedients.length === 0 ? (
              <p>No hay expedientes asociados a este proveedor.</p>
            ) : (
              expedients.map((expedient) => {
                const isExpanded = expandedExpedients[expedient.expedientNumber];

                return (
                  <div key={expedient.expedientNumber} className={styles.expedientCard}>
                    <div
                      className={styles.expedientHeader}
                      onClick={() => toggleExpedient(expedient.expedientNumber)}
                    >
                      <h3>
                        Expediente #{expedient.expedientNumber} - {expedient.groupName}
                      </h3>
                      <span className={styles.expandIcon}>{isExpanded ? '-' : '+'}</span>
                    </div>
                    {isExpanded && (
                      <div className={styles.expedientContent}>
                        {/* Primera fila */}
                        <div className={styles.expedientFormRow}>
                          <div className={styles.expedientFormGroup}>
                            <label>Nombre del Grupo</label>
                            <input
                              type="text"
                              value={expedient.groupName || '-'}
                              readOnly
                            />
                          </div>
                          <div className={styles.expedientFormGroup}>
                            <label>Fecha de Apertura</label>
                            <input
                              type="text"
                              value={expedient.apertureDate || '-'}
                              readOnly
                            />
                          </div>
                          <div className={styles.expedientFormGroup}>
                            <label>Fecha de Cierre</label>
                            <input
                              type="text"
                              value={expedient.closureDate || '-'}
                              readOnly
                            />
                          </div>
                          <div className={styles.expedientFormGroup}>
                            <label>Fecha de Entrada</label>
                            <input
                              type="text"
                              value={expedient.entryDate || '-'}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* Segunda fila */}
                        <div className={styles.expedientFormRow}>
                          <div className={styles.expedientFormGroup}>
                            <label>Fecha de Salida</label>
                            <input
                              type="text"
                              value={expedient.departureDate || '-'}
                              readOnly
                            />
                          </div>
                          <div className={styles.expedientFormGroup}>
                            <label>Días</label>
                            <input
                              type="number"
                              value={expedient.days || 0}
                              readOnly
                            />
                          </div>
                          <div className={styles.expedientFormGroup}>
                            <label>Vendedor</label>
                            <input
                              type="text"
                              value={expedient.salesman || '-'}
                              readOnly
                            />
                          </div>
                          <div className={styles.expedientFormGroup}>
                            <label>Destino</label>
                            <input
                              type="text"
                              value={expedient.destination || '-'}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* Botón para ver expediente completo */}
                        <div className={styles.buttonGroupRight}>
                          <button
                            type="button"
                            className={styles.viewExpedientButton}
                            onClick={() => handleViewExpedient(expedient.expedientNumber)}
                          >
                            Ver expediente completo
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}

        {/* Botones de Acción */}
        {activeTab === 'Formulario' && (
          <div className={styles.buttonGroup}>
            <button
              type="submit"
              className={styles.submitButton}
              aria-label={isEditMode ? 'Guardar Cambios' : 'Crear Proveedor'}
            >
              {isEditMode ? 'Guardar Cambios' : 'Crear Proveedor'}
            </button>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={handleCancel}
              aria-label="Cancelar"
            >
              Cancelar
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default SupplierForm;